import "react-app-polyfill/ie11";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App";
import Login from "./pages/Login/Login";

//import * as serviceWorker from './serviceWorker';
import { HashRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import ResetSenha from "./pages/Login/LoginReset";

ReactDOM.render(
  <HashRouter>
    <ScrollToTop>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_CLIENTE}
      >
        <Route exact path="/reset/:id/:tokenReset" component={ResetSenha} />

        <Route
          exact
          path="/"
          render={() => {
            return <App />;
          }}
        />
      </GoogleReCaptchaProvider>
    </ScrollToTop>
  </HashRouter>,

  document.getElementById("root")
);

// https://www.npmjs.com/package/react-google-recaptcha-v3

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
