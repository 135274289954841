import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory, Link } from "react-router-dom";
import { Button } from "primereact/button";

import { CartaoVirtualService } from "../../service/Servidor/CartaoVirtualService";
import { EsteiraService } from "../../service/EsteiraService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const _Historico = () => {

  const dt = useRef(null);
  const toast = useRef(null);
  const history = useHistory();
  const { servidor, id } = useParams();

  const cartaoVirtual = new CartaoVirtualService();
  const esteira = new EsteiraService();

  const _header = "Administrativo - Operacional - Esteira - Solicitações - Histórico";
  const link_voltar = "/esteiraServidor_Index/" + servidor;

  const codigo = id.toString().padStart(3, '0')

  const header = (
    <div className="flex flex-row justify-content-between">
      <div className="flex flex-column">
        <p className="text-2xl font-bold" style={{color: "#6366F1"}}>#{codigo}</p>
        <p className="text-2xl font-bold">Histórico</p>
      </div>
      <div className="flex flex-row gap-5" style={{alignItems: 'center'}}>
        <p className="m-0">Adicionar Motivo</p>
        <Link to={link_voltar}>
          <Button
            icon={"pi pi-plus"}
            className="p-button-rounded p-button-warning"
          />
        </Link>
      </div>
    </div>
  );

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [dadosServidor, setDadosServidor] = useState({});

  const [historico, setHistorico] = useState([]);

  useEffect(()=>{
    setLoadingSpinner(true)
    cartaoVirtual.getById(servidor)
    .then(
      (data) => 
        data.status === true
          ? setDadosServidor(data.dados[0])
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");        
    });

    esteira.historico(id)
    .then(
      (data) => 
        data.status === true
          ? setHistorico(data.dados)
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");      
    });

  }, []);

  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  function downloadFile(code, fileName) {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${code}`;
    link.download = fileName;
  
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }

  const pageHeader = () => {
    return (
      <div>
        <p className="text-xl">{_header}</p>
        <div className="my-5">
          <Link to={link_voltar}>
            <Button
              label="Voltar"
            />
          </Link>
        </div>
        <p className="text-2xl">{dadosServidor.cav_nome}</p>
      </div>
    );
  };

  const dataBodyTemplate = (data) => {
    return (
      <p>{data.data}</p>
    )
  }

  const statusBodyTemplate = (data) => {
    return (
      <p>{data.status}</p>
    )
  }

  const motivoBodyTemplate = (data) => {
    return (
      <p>{data.motivo}</p>
    )
  }

  const fileBodyTemplate = (data) => {
    return (
      !data.arquivo ? "--"
      : <Button
        tooltip={"Arquivo"}
        icon={"pi pi-download"}
        className="p-button-rounded p-button-warning"
        onClick={()=>downloadFile(data.arquivo, "solicitacao-de-aprovacao-" + id + ".pdf")}
      />
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={pageHeader}></Toolbar>
          <DataTable
            ref={dt}
            value={historico}
            dataKey="id"
            paginator
            rows={25}
            rowsPerPageOptions={[10, 25, 50]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="data"
              header="Data"
              body={dataBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
              sortable
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
              sortable
            ></Column>
            <Column
              field="motivo"
              header="Motivo"
              body={motivoBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
              sortable
            ></Column>
            <Column             
              field="arquivo"
              header="Arquivo"
              body={fileBodyTemplate}
              headerStyle={{ width: "25%", minWidth: "10rem" }}
            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Historico, comparisonFn);
