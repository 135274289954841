import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Funcoes } from "../../utils/funcoes.js";
import { OperadoraService } from "../../service/OperadoraService";
import { ConvenioService } from "../../service/ConvenioService";
import { DashBoardService } from "../../service/DashBoardService";
import { useHistory } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { AgendaCorteService } from "../../service/AgendaCorteService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./styles.css";

const _Index = () => {

  const optionsYears = [
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ];
  const [anosSelecionados, setAnosSelecionados] = useState(['2024']);
  const [selectedAll, setSelectAll] = useState(null);
  const handleAnosChange = (e) => {
      if (e.value.length === 0) {
      const lastOption = optionsYears.slice(-1)[0];
      setAnosSelecionados([lastOption.value]);} 
      else {setAnosSelecionados(e.value);}
      setSelectAll(e.value.length === optionsYears.length);
    return;
  };

  useEffect(() => {
    if (!selectedAll && anosSelecionados.length === 0 && optionsYears.length > 0) {
      const lastOption = optionsYears.slice(-1)[0];
      setAnosSelecionados([lastOption.value]);
    }
  }, [selectedAll, anosSelecionados, optionsYears]);
  
  const [mes, setMes] = useState([]);
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  const [operadora, setOperadora] = useState([
    { ope_id: 0, ope_nome: "Todos" },
  ]);
  const [convenio, setConvenio] = useState([
    { con_id: 0, con_nome_fantasia: "Todos"},
  ]);
  const [opeId, SetOpeID] = useState(null);
  const convenioService = new ConvenioService();
  const operadoraService = new OperadoraService();
  const dashBoardService = new DashBoardService();
  const funcoes = new Funcoes();
  const [modelDado, setModelDado] = useState(null);
  const history = useHistory();

  const [isClicked_11, setIsClicked_11] = useState(true);
  const [isClicked_12, setIsClicked_12] = useState(false);
  const [isClicked_13, setIsClicked_13] = useState(false);
  const [isClicked_14, setIsClicked_14] = useState(false);
  const [isClicked_21, setIsClicked_21] = useState(true);
  const [isClicked_22, setIsClicked_22] = useState(false);
  const [isClicked_23, setIsClicked_23] = useState(false);
  const [isClicked_24, setIsClicked_24] = useState(false);
  const [isClicked_25, setIsClicked_25] = useState(false);
  const [isClicked_31, setIsClicked_31] = useState(true);
  const [isClicked_32, setIsClicked_32] = useState(false);
  const [isClicked_33, setIsClicked_33] = useState(false);  
  const [isClicked_42, setIsClicked_42] = useState(false);
  const [isClicked_43, setIsClicked_43] = useState(false);
  const [isClicked_51, setIsClicked_51] = useState(true);
  const [isClicked_52, setIsClicked_52] = useState(false);

  const corSelecionado = "#e7e7e7";
  const corNaoSelecionado = "#F4F4F4";

  useEffect(() => {
    setMes(funcoes.geraArrayMes());

    operadoraService
      .getAll()
      .then((data) => setOperadora([{ ope_id: 0, ope_nome: "Todos" }, ...data.dados]))
      .catch((error) => {
        history.push("/semToken");
      });

    agendaCorteService
      .getAllAnoMes()
      .then((data) => setAgendaCortes(data.dados))
      .catch((error) => {
        history.push("/semToken");
      });

    SetLoadingSpinner(false);
  }, []);

  useEffect(() => {
    buscaConvenios();
  }, [opeId]);

  useEffect(() => {
    formik.values.cav_codigo_convenio = 0;
  }, [convenio]);

  useEffect(() => {
    console.log(modelDado)
  }, [modelDado])

  function buscaConvenios() {
    SetLoadingSpinner(true);
    convenioService
      .getByOperadora(formik.values.cav_ope_id)
      .then((data) =>
        setConvenio([{ con_id: 0, con_nome_fantasia: "Todos" }, ...data.dados])
      );
    SetLoadingSpinner(false);
  }

  const formik = useFormik({
    initialValues: {
      cav_codigo_convenio: 0,
      cav_ope_id: 0,
      anosSelecionados: funcoes.geraArrayAno(),
      mes: 0,
    },
    validationSchema: yup.object({
      cav_ope_id: yup.string().required("O campo é obrigatório.").nullable(),
      cav_codigo_convenio: yup.string().required("O campo é obrigatório.").nullable()
    }),
    onSubmit: (values) => {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            dashBoardService
              .getId(
                anosSelecionados,
                formik.values.mes,
                formik.values.cav_ope_id,
                formik.values.cav_codigo_convenio,
                token
              )
              .then((data) => setModelDado(data), SetLoadingSpinner(false))
              .catch((error) => {
                history.push("/semToken");
              });
          });
      });
    },
  });

  function alteraCoresTabPanel(codigo) {
    setIsClicked_11(false);
    setIsClicked_12(false);
    setIsClicked_13(false);
    setIsClicked_14(false);

    setIsClicked_21(false);
    setIsClicked_22(false);
    setIsClicked_23(false);
    setIsClicked_24(false);
    setIsClicked_25(false);

    setIsClicked_31(false);
    setIsClicked_32(false);
    setIsClicked_33(false);

    
    setIsClicked_42(false);
    setIsClicked_43(false);

    setIsClicked_51(false);
    setIsClicked_52(false);

    switch (codigo) {
      case 11:
        setIsClicked_11(true);
        break;
      case 12:
        setIsClicked_12(true);
        break;
      case 13:
        setIsClicked_13(true);
        break;
      case 14:
        setIsClicked_14(true);
        break;
      case 21:
        setIsClicked_21(true);
        break;
      case 22:
        setIsClicked_22(true);
        break;
      case 23:
        setIsClicked_23(true);
        break;
      case 24:
        setIsClicked_24(true);
        break;
      case 25:
        setIsClicked_25(true);
        break;
      case 31:
        setIsClicked_31(true);
        break;
      case 32:
        setIsClicked_32(true);
        break;
      case 33:
        setIsClicked_33(true);
        break;      
      case 42:
        setIsClicked_42(true);
        break;
      case 43:
        setIsClicked_43(true);
        break;
      case 51:
        setIsClicked_51(true);
        break;
      case 52:
        setIsClicked_52(true);
        break;
    }
  }

  // =========================  TABLE ===================================
  const _header = "Agenda de Corte";
  const [agendaCortes, setAgendaCortes] = useState(null);
  const agendaCorteService = new AgendaCorteService();
  const dt = useRef(null);
  const [selectedAgendaCortes, setSelectedAgendaCortes] = useState(null);

  //Formata YYYYMM para YYYY/MM
  function formataAnoMes(dataPraF) {
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(4, 6);
    const f = ano + "/" + mes;
    return f;
  }

  //Formata datas para padrão PTBR
  function formataDataInicialFinal(dataPraF) {
    if (dataPraF === null || dataPraF === "") {
      return null;
    }
    const ano = dataPraF.toString().slice(0, 4);
    const mes = dataPraF.toString().slice(5, 7);
    const dia = dataPraF.toString().slice(8, 10);
    const f = dia + "/" + mes + "/" + ano;
    return f;
  }

  const convenioBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.con_nome_fantasia}
      </>
    );
  };

  const processadoraBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.ope_nome}
      </>
    );
  };

  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Id</span>
        {rowData.agc_id}
      </>
    );
  };

  const anoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataAnoMes(rowData.agc_ano_mes)}
      </>
    );
  };

  const diaBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {rowData.agc_dia}
      </>
    );
  };

  const dataInicialTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_data_inicial)}
      </>
    );
  };

  const dataFinalTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_data_final)}
      </>
    );
  };

  const geracaoCartaoTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">name</span>
        {formataDataInicialFinal(rowData.agc_geracao_cartao)}
      </>
    );
  };

  const permissaoAcesso = (num) => {
    const teste = sessionStorage.getItem("acessos");
    const acessos = teste.trim().split(",");
    let achou = acessos.find((item) => item === num);
    return achou === undefined ? false : true;
  };

  // --------------- GERAÇÃO DO ARQUIVO DE CORTE .TXT -----------------------

  const gerarDadosTxt = (id_agenda) => {
    SetLoadingSpinner(true);
    agendaCorteService
      .getGerarDadosCorte(id_agenda)
      .then((data) => geraArquivo(data));
  };

  const geraArquivo = (dados_) => {
    if (dados_.status) {
      const linkSource = dados_.dados["base64"];
      const downloadLink = document.createElement("a");
      const fileName =
        dados_.dados["nomearquivo"] + "." + dados_.dados["extensao"];

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
    SetLoadingSpinner(false);
    toast.current.show({
      severity: dados_.status ? "success" : "error",
      summary: dados_.mensagem,
      life: 3000,
    });
  };

  // -------------------------------------------------------------------------

  const actionBodyTemplate = (rowData) => {
    let _acesso_Gerar = "adm/agenda/visualizar";
    return (
      <div className="grid">
        <div className="col-4 md:col-12">
          {rowData.botao_habilitado ? (
            <Button
              label={"Gerar TXT"}
              icon={permissaoAcesso(_acesso_Gerar) ? "" : "pi pi-lock"}
              tooltip={"Agenda de Corte"}
              className="p-button-raised p-button-success "
              value={rowData.agc_id}
              onClick={(e) => gerarDadosTxt(rowData.agc_id)}
            />
           ) : null} 
        </div>
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
    </div>
  );

  // ====================================================================
  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-2">
                <MultiSelect
                  id="ano"
                  value={anosSelecionados}
                  disabled={false}
                  onChange={handleAnosChange}
                  onSelectAll={(e) => {
                    setAnosSelecionados(e.checked ? [] : optionsYears.map((item) => item.value));
                    setSelectAll(!e.checked);
                  }}
                  options={optionsYears}
                  selectAll={selectedAll}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Selecione"
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.anosSelecionados &&
                      formik.errors.anosSelecionados,
                  })}
                />
                {formik.touched.anosSelecionados &&
                formik.errors.anosSelecionados ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.anosSelecionados}
                  </div>
                ) : null}
                <label htmlFor="ano">Ano</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="mes"
                  value={formik.values.mes}
                  disabled={false}
                  onChange={formik.handleChange}
                  options={mes}
                  optionLabel="mes"
                  optionValue="id"
                  placeholder={formik.values.mes}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid": formik.touched.mes && formik.errors.mes,
                  })}
                />
                {formik.touched.mes && formik.errors.mes ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.mes}
                  </div>
                ) : null}
                <label htmlFor="mes">Mês</label>
              </span>
            </div>
          </div>

          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-2">
                <Dropdown
                  id="cav_ope_id"
                  value={formik.values.cav_ope_id}
                  disabled={false}
                  onChange={formik.handleChange}
                  options={operadora}
                  optionLabel="ope_nome"
                  optionValue="ope_id"
                  onHide={buscaConvenios}
                  placeholder={formik.values.cav_ope_id}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cav_ope_id && formik.errors.cav_ope_id,
                  })}
                />
                {formik.touched.cav_ope_id && formik.errors.cav_ope_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cav_ope_id}
                  </div>
                ) : null}
                <label htmlFor="cav_ope_id">Processadora</label>
              </span>
            </div>
          </div>

          <div className="col-6 md:col-5">
            <div className="field">
              <span className="p-float-label  mt-2">
                <Dropdown
                  id="cav_codigo_convenio"
                  disabled={false}
                  value={formik.values.cav_codigo_convenio}
                  onChange={formik.handleChange}
                  options={convenio}
                  optionLabel="con_nome_fantasia"
                  optionValue="con_id"
                  placeholder={
                    formik.values.cav_codigo_convenio === ""
                      ? "0"
                      : formik.values.cav_codigo_convenio
                  }
                  emptyMessage={"Convênio deve ser selecionado"}
                  className={classNames({
                    "p-invalid":
                      formik.touched.cav_codigo_convenio &&
                      formik.errors.cav_codigo_convenio,
                  })}
                />

                {formik.touched.cav_codigo_convenio &&
                  formik.errors.cav_codigo_convenio ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.cav_codigo_convenio}
                  </div>
                ) : null}
                <label htmlFor="cav_codigo_convenio">Convênio *</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-2">
                <Button
                  type="submit"
                  label="Buscar"
                  className="p-button-raised mr-2"
                  onClick={formik.handleSubmit}
                />
              </span>
            </div>
          </div>
        </div>
      </form>
      <TabView>
        <TabPanel header="Visão Geral (Quantidade)">
          <div className="grid">
            <div className="flex col-12 lg:col-6 xl:col-3">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-users  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Usuários cadastrados
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado ? modelDado["dados"]["visao_0101"] : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-3">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1">
                  <div className="flex  justify-content-start align-items-stretch">
                    <div className=" flex align-items-center border-circle  gap-3">
                      <i
                        className="pi pi-credit-card  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">Cartões</p>
                    </div>
                  </div>
                  <div className="flex justify-content-end align-items-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado ? modelDado["dados"]["visao_0102"] : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-3">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-money-bill  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Operações de empréstimos
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado ? modelDado["dados"]["visao_0103"] : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-3">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-shield  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Seguros contradados
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado ? modelDado["dados"]["visao_0104"] : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Visão Geral (Valores)">
          <div className="cards-container">
            <div className="flex">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-credit-card  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total gastos com Cartão Benefício
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0201"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-wallet  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total que foi Emprestado
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0202"][
                          "valor_total_emprestado"
                          ]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-chart-bar  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total Líquido
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0204"][
                          "valor_total_liquido"
                          ]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-shield text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total de Contratação de Seguros
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0203"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-user-minus text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Inadimplentes
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0205"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Cartão Benefício">
          <div className="grid">
            <div className="flex col-12 lg:col-6 xl:col-4">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-credit-card  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valores totais de averbação (Resgates)
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0301"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-4">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-credit-card  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valores totais gastos com cartão Benefício
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0302"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-4">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-credit-card  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Saldo disponível (valores não gastos)
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0303"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Canais de Vendas">
          <div className="grid">
            

            <div className="flex col-12 lg:col-6 xl:col-6">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-chart-pie text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Vendas realizadas pelo Aplicativo UseDigi (Quantidade e
                        Valores)
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end valueCardContainer">
                    <div className="valueCard text-100 font-medium text-xl">
                      {modelDado
                        ? modelDado["dados"]["visao_0402"]["qtde"]
                        : null}
                    </div>
                    <div className="valueCard text-100 font-medium text-xl">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0402"]["valor"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-6">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-chart-pie  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Vendas realizadas pela processadora (Quantidade e
                        Valores)
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end valueCardContainer">
                    <div className="valueCard text-100 font-medium text-xl">
                      {modelDado
                        ? modelDado["dados"]["visao_0403"]["qtde"]
                        : null}
                    </div>
                    <div className="valueCard text-100 font-medium text-xl">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0403"]["valor"]
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Repasses">
          <div className="grid">
            <div className="flex col-12 lg:col-6 xl:col-6">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-money-bill  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total quitado
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end ">
                    <br></br>
                  </div>
                  <div className="flex justify-content-end ">
                    <div className="valueCard  text-100 font-medium text-xl ">
                      {modelDado
                        ? funcoes.formataValorParaBR(
                          modelDado["dados"]["visao_0501"]
                        )
                        : null}
                    </div>
                  </div>                  
                </div>
              </Button>
            </div>

            <div className="flex col-12 lg:col-6 xl:col-6">
              <Button
                className="card mb-0 shadow-lg"
                style={{
                  borderRadius: "1rem",
                  borderWidth: 0,
                  background: '#F4F4F4',
                  // background: isClicked_11 ? corSelecionado : corNaoSelecionado,
                  cursor: 'default'
                }}
                // onClick={() => alteraCoresTabPanel(11)}
              >
                <div className=" flex-1  ">
                  <div className="flexjustify-content-start mb-3">
                    <div className="  flex align-items-start border-circle  gap-3">
                      <i
                        className="pi pi-money-bill  text-purple-500"
                        style={{ fontSize: "2rem" }}
                      />
                      <p className="tittleCard text-left ">
                        Valor total pendente
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-content-end valueCardContainer ">
                    <div className="flex justify-content-end ">
                      <div className="valueCard text-100 font-medium text-xl">
                        {modelDado
                          ? modelDado["dados"]["visao_0502"]["qtde"]
                          : null}
                      </div>
                    </div>
                    <div className="flex justify-content-end ">
                      <div className="valueCard text-100 font-medium text-xl">
                        {modelDado
                          ? funcoes.formataValorParaBR(
                            modelDado["dados"]["visao_0502"]["valor"]
                          )
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <LoadingSpinner visualiza={loadingSpinner} />

      {/* ============================= TABLE ====================================  */}
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toast ref={toast} />
            <DataTable
              ref={dt}
              value={agendaCortes}
              selection={selectedAgendaCortes}
              onSelectionChange={(e) => setSelectedAgendaCortes(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
              emptyMessage="Não há dados."
              header={header}
              responsiveLayout="scroll"
            >
              <Column
                field="ope_nome"
                header="Processadora"
                sortable
                body={processadoraBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="con_nome_fantasia"
                header="Convênio"
                sortable
                body={convenioBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="agc_id"
                header="Id"
                hidden={true}
                sortable
                body={codeBodyTemplate}
                headerStyle={{ width: "14%", minWidth: "10rem" }}
              ></Column>
              <Column
                field="agc_ano_mes"
                header="Ano/Mês"
                sortable
                body={anoBodyTemplate}
                headerStyle={{ width: "20%", minWidth: "5rem" }}
              ></Column>
              <Column
                field="agc_dia"
                header="Dia Corte"
                sortable
                body={diaBodyTemplate}
                headerStyle={{ width: "20%", minWidth: "5rem" }}
              ></Column>
              <Column
                field="agc_data_inicial"
                header="Data Inicial"
                sortable
                body={dataInicialTemplate}
                headerStyle={{ width: "20%", minWidth: "5rem" }}
              ></Column>
              <Column
                field="agc_data_final"
                header="Data Final"
                sortable
                body={dataFinalTemplate}
                headerStyle={{ width: "20%", minWidth: "5rem" }}
              ></Column>
              <Column
                field="agc_geracao_cartao"
                header="Geração Corte"
                sortable
                body={geracaoCartaoTemplate}
                headerStyle={{ width: "25%", minWidth: "5rem" }}
              ></Column>

              <Column
                header="Ações"
                align={"center"}
                body={actionBodyTemplate}
                headerStyle={{ width: "100%", minWidth: "15rem" }}
              ></Column>
            </DataTable>
            <LoadingSpinner visualiza={loadingSpinner} />
          </div>
        </div>
      </div>

      {/* ============================================================================ */}
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
