import "./style.css";

const CardInfo = ({ value, secondaryText, opitionalText = "" }) => {
  return (
    <>
      <div className="cardInfo">
        <p className="opitionalText" > { opitionalText } </p>
        <p className="mainText"> { value } </p>
        <p className="secondaryText" > { secondaryText } </p>
      </div>
    </>
  )
}

export default CardInfo