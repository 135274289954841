import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./Login.css";
import "./Validate";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { Dialog } from "primereact/dialog";
import { LoginService } from "../../service/LoginService";
import Teclado from "../../components/Teclado/Teclado";
import Validate from "./Validate";
import { useSessionStorage } from "../../storage/UseSessionStorage";
import LoadingSpinner from "../../components/LoadingSpinner";


export default function Login({ setToken }) {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null); // Utilizado para apresentar mensagens
  const [loadingSpinner, SetLoadingSpinner] = useState(false);
  let emptyJson = {
    status: false,   
    dados: "",
    mensagem:"",
    exception:""
  };
 

  const [dados, setDados] = useState(emptyJson); // Utilizado para receber dados do axios

  const [submitted, setSubmitted] = useState(false);

  let emptylogin = {
    userName: "",
    password: null,
  };

 

  const [login, setLogin] = useState(emptylogin);
 

  /**
   *------------ TECLADO -----------
   */
  const [tecladoDialog, setTecladoDialog] = useState(false);
  const openNew = () => {    
    setTecladoDialog(true);
  };
  const tecladoHide = () => {
    setTecladoDialog(false);
  };

  const tecladoClose = () => {
    setTecladoDialog(false);
  };

  const tecladoPassword = (num) => {
    let _login = { ...login };
    _login["password"] = num;
    setLogin(_login);
    setTecladoDialog(false);
  };

  /**
   *--------- FIM TECLADO -----------
   */


   

   useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",    
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE}`,

      
      function () { }
    );
  }, []);


  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _login = { ...login };
    _login[`${name}`] = val;
    setLogin(_login);
  };

  const esqueciMinhaSenha = () => {
    SetLoadingSpinner(true);
    const usuario = new LoginService();
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
          action: "submit",
        })
        .then((token) => {
          usuario.esqueciMinhaSenha(login.userName,token).then((res) => {
            toast.current.show({
              severity: res.status ? "success" : "error",
              summary: res.mensagem,
              life: 7000,
            });
            SetLoadingSpinner(false);
          });
        });
    });
    
  };

  useEffect(() => {
    sessionStorage.clear();
    if (dados.status === true) {      
      let _token = JSON.stringify(dados.dados.token);      
      let _acessos = JSON.stringify(dados.dados.acessos);
      _token = _token.replace('"', "").replace('"', "");
      _acessos = _acessos.replace('"', "").replace('"', "");
      sessionStorage.setItem("acessos", _acessos);
      sessionStorage.setItem("dados", JSON.stringify(dados.dados.usuario_nome));
      sessionStorage.setItem("empresa_id", JSON.stringify(dados.dados.usuario_empresa));
      sessionStorage.setItem("token", _token);
      sessionStorage.setItem("logado", true);
      let token = sessionStorage.getItem("token");
      if (!token) {        
        return false;
      } else {
        setToken(token);
        return true;
      }
    } else if (dados.mensagem !== "") {
      SetLoadingSpinner(false);
      toast.current.show({
        severity: dados.status ? "success" : "error",
        summary: dados.mensagem,
        life: 3000,
      });      
      return false;
    }
  }, [dados]);

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const usuario = new LoginService();

    setSubmitted(true);
    
    if (!Validate(login)) {
      SetLoadingSpinner(true);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
            action: "submit",
          })
          .then((token) => {
            const req = usuario.post(login.userName, login.password,token).then((res) => {
              setDados(res);
            });           
          });
      });


     
      
    }
  };

  useEffect(() => {
    
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
      

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        
        script.onload = function () {
          if (callback)  callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    
    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE}`,
      function () {}
    );
  }, []);


  let numeros = [];

  for (let i = 0; i < 10; i++) {
    let numero = Math.floor(Math.random() * 10);

    while (numeros.includes(numero)) {
      numero = Math.floor(Math.random() * 10);
    }

    numeros.push(numero);
  }

  return (
    <div className="grid">
 
      <Toast ref={toast} />
      <div className="center-form">
      
        <form onSubmit={handleSubmit}>
        
          <div className="card p-fluid">
          
            <img
              src="assets/layout/images/logo.png"
              alt="Logo"
              height="60"
              className="mr-4"
            />
            
            <h1 className="titulo">Administrativo</h1>
            <LoadingSpinner visualiza={loadingSpinner} />
            <div className="field">
              <div className="col-12 md:col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon ">
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    id="userName"
                    placeholder="Usuário"
                    maxLength={50}
                    value={login.userName}
                    onChange={(e) => onInputChange(e, "userName")}
                    required
                    autoFocus
                    className={classNames({
                      "p-invalid": submitted && !login.userName,
                    })}
                  />
                </div>
              </div>
              
            </div>

            <div className="field">
              <div className="col-12 md:col-12">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon ">
                    <i className="pi pi-key"></i>
                  </span>
                  <InputText
                    onClick={openNew}                         
                    id="password"
                    type="password"
                    placeholder="Senha de Acesso"
                    maxLength={6}
                    value={login.password}
                    // onChange={(e) => onInputChange(e, "password")}
                    onChange={openNew}
                    required
                    autoFocus                    
                    className={classNames({
                      "p-invalid": submitted && !login.password,
                    })}
                  />
                </div>
               
             
              </div>
            </div>

            <div className="col-12 md:col-12">
              <Button
                type="submit"
                label="Confirmar"
                className="p-button-success mr-2 mb-2"
                onClick={handleSubmit}
              />
            </div>
            
          </div>

          <Dialog visible={tecladoDialog} onHide={tecladoHide }>
            <Teclado
              maxDigitos={6}
              tecladoClose={tecladoClose}
              tecladoPassword={tecladoPassword}
              numeros={numeros}
            />
          </Dialog>
      

        </form>

        <div className="col-12 md:col-12">
        
          <button
            className="p-link layout-topbar-button"
            onClick={() => esqueciMinhaSenha()}
            style={{ marginRight: ".25em" }}
          >
            <span>Esqueci minha senha</span>
          </button>
        </div>
      </div>

    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
