import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Funcoes } from "../../../utils/funcoes";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from 'primereact/inputswitch';

import LoadingSpinner from "../../../components/LoadingSpinner";
import Exportar from "../../../components/Exportar";
import { PushService } from "../../../service/Notificacao/PushService";

const Envios = () => {

  const history = useHistory();

  const toast = useRef(null);
  const dt = useRef(null);

  const utils = new Funcoes();
  const pushService = new PushService();

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [campanha, setCampanha] = useState(0);
  const [campanhaSelectList, setCampanhaSelectList] = useState([]);

  const [envios, setEnvios] = useState([]);
  
  const [statusEnvio, setStatusEnvio] = useState(0);
  const statusList = [
    {label: "Salvo", value: 1},
    {label: "Programado", value: 2},
    {label: "Enviados", value: 3},
  ];

  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  useEffect(() => {
    if (retornoDado.status === false) {
       
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  const exportBtn = () => {
    const cols = [
      { field: "campanha", header: "Campanha" },
      { field: "titulo", header: "Título" },
      { field: "mensagem", header: "Mensagem" },
      { field: "link", header: "Link" },
      { field: "data_cadastro", header: "Data Cadastro" },
      { field: "dia_agendado", header: "Dia Agendado" },
      { field: "hora_agendada", header: "Horário Agendado" },
      { field: "status_envio", header: "Status Envio" },
      { field: "cliques", header: "Cliques" },
      { field: "enviados", header: "Enviados" },
    ];

    return (
      <React.Fragment>
        <Exportar dt={dt} cols={cols} dadosService={envios} id="header-export-btn"/>
      </React.Fragment>
    );
  };
  
  const formFields = (
    <>
      <div className="grid gap-5">
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Data Início</span>
          <InputMask
            mask={"De: 99/99/9999"}
            type="text"
            id="form-initial-date"
            className="text-xl"
            onChange={(data)=>setDataInicial(data.value)}
            placeholder="De: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Data Final</span>
          <InputMask
            mask={"Até: 99/99/9999"}
            type="text"
            id="form-final-date"
            className="text-xl"
            onChange={(data)=>setDataFinal(data.value)}
            placeholder="Até: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Campanha</span>
          <Dropdown
            id="form-campanha"
            value={campanha} 
            options={campanhaSelectList}
            className="text-xl"
            onChange={(data)=>setCampanha(data.value)}
            placeholder="Selecione..."
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Status de Envio</span>
          <Dropdown
            id="form-status-envio"
            value={statusEnvio}
            options={statusList}
            className="text-xl"
            onChange={(data)=>setStatusEnvio(data.value)}
            placeholder="Selecione..."
          />
        </div>
      </div>
    </>
  );
  
  function submitSearchForm() {

    const [initialDate, finalDate] = [utils.handleDate(dataInicial), utils.handleDate(dataFinal)];

    if (!utils.isValidDate(initialDate) && !initialDate) {
      setRetornoDado({
        status: false,
        msg: "Data início inválida.",
      }) 
      return false
    }

    if (!utils.isValidDate(finalDate) && !finalDate) {
      setRetornoDado({
        status: false,
        msg: "Data final inválida.",
      }) 
      return false
    }

    setLoadingSpinner(true);
    pushService
      .getPushList(initialDate, finalDate, campanha, statusEnvio).then(
        (data) =>
          data.status === true ? setEnvios(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        console.error(error)
        history.push("/semToken");
     });

  }

  function clearFormFields() {
    document.getElementById("form-initial-date").value = "";
    document.getElementById("form-final-date").value = "";
    setCampanha(0);
    setStatusEnvio(0);
  }

  const formSubmit = (
    <>
      <div className="flex flex-row gap-2">
        <Button label="Buscar" onClick={submitSearchForm} id="form-search-btn"></Button>
        <Button label="Limpar" className="p-button-outlined" onClick={clearFormFields} id="form-clear-btn"></Button>
      </div>
    </>
  )

  const getCampanhas = async () => {

    setLoadingSpinner(true);
    pushService
      .getCampanhasList().then(
        (data) =>
          data.status === true ? setCampanhaSelectList(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
     });

  }

  useEffect(()=>{
    getCampanhas();
  }, [])

  function activeSwitch(id, status){   
    
    setEnvios(
      envios.map(item =>
        item.id === id
          ? ({ ...item, ativo: status })
          : item
      )
    );
  
  }
  
  function handleSwitchActive(id, status){

    setLoadingSpinner(true);
    pushService
      .activatePush(id, status).then(
        (data) =>
          data.status === true ? activeSwitch(id, status) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
     });

  }

  const imageTemplate = (data) => {
    return <>
      <img 
        alt="Banner Principal" 
        src={data.image}
        style={{width: '100%', height: '100%'}}
      />
    </>
  }

  const campainTemplate = (data) => {
    return <>
      <p>{data.campanha}</p>
    </>
  } 

  const titleTemplate = (data) => {
    return <>
      <p>{data.titulo}</p>
    </>
  } 

  const messageTemplate = (data) => {
    return <>
      <p>{data.mensagem}</p>
    </>
  } 

  const linkTemplate = (data) => {
    return <>
      <p>{data.link}</p>
    </>
  } 

  const dateTemplate = (data) => {
    const datetime = data.data_cadastro.split(" ");

    return <>
      <p>{utils.formataDataParaDDMMYYYY(datetime[0]) + " " + utils.formataHoraParaHHII(datetime[1])}</p>
    </>
  } 

  const dayTemplate = (data) => {
    return <>
      <p>{utils.formataDataParaDDMMYYYY(data.dia_agendado)}</p>
    </>
  } 

  const hourTemplate = (data) => {
    return <>
      <p>{utils.formataHoraParaHHII(data.hora_agendada)}</p>
    </>
  }

  const statusTemplate = (data) => {
    const statusLabelList = [
      "",
      "Salvo",
      "Programado",
      "Enviados",
    ]

    return <>
      <p>{statusLabelList[data.status_envio]}</p>
    </>
  }

  const clicksTemplate = (data) => {

    const cliques = data.cliques ? data.cliques : 0;
    const cliquesPercentage = data.enviados === 0 ? 0 : (cliques / data.enviados) * 100;

    return <>
      <p>{parseFloat(cliquesPercentage).toFixed(2)}% ({cliques})</p>
    </>
  }

  const sentTemplate = (data) => {
    return <>
      <p>{data.enviados}</p>
    </>
  }

  const actionsTemplate = (data) => {
    return (
      <>
        <div className="flex flex-row px-2">
          <div className="col-3 m-auto">
            <Link to={utils.permissaoAcesso("ntf/envio/push/alterar") && `/notificacao/criar/1/${data.campanha_id}` }>
              <Button
                icon={utils.permissaoAcesso("ntf/envio/push/alterar") ? "pi pi-pencil" : "pi pi-lock"}
                className="p-button-rounded p-button-success mr-6"
                id={"table-action-edit-" + data.id}
              />
            </Link>
          </div>
          <div className="col-3 m-auto">
            <Link to={utils.permissaoAcesso("ntf/envio/push/visualizar") && `/notificacao/criar/2/${data.campanha_id}`}>
              <Button
                icon={utils.permissaoAcesso("ntf/envio/push/visualizar") ? "pi pi-eye" : "pi pi-lock"}
                className="p-button-rounded p-button-warning mt-12"
                id={"table-action-view-" + data.id}
              />
            </Link>
          </div>
          <div className="col-3 m-auto">
            <InputSwitch 
              value={data.ativo}
              checked={data.ativo}
              disabled={!utils.permissaoAcesso("ntf/envio/push/alterar")}
              onChange={(e) => {
                handleSwitchActive(data.id, e.value)
              }} 
              id={"table-action-switch-" + data.id}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          
          <Toolbar
            className="mb-4"
            left={
              <Link to={utils.permissaoAcesso("ntf/envio/push/criar") && "/notificacao/select"}>
                  <Button 
                    icon={utils.permissaoAcesso("ntf/envio/push/criar") ? "pi pi-plus" : "pi pi-lock"}
                    label="Criar Notificação" 
                    className="p-button-success"
                    id="header-create-notification-btn"
                  />
              </Link>
            }
            right={exportBtn}
          ></Toolbar>
          
          <Toolbar
            className="mb-4"
            left={formFields}
            right={formSubmit}
          ></Toolbar>
        
          <DataTable
            ref={dt}
            value={envios}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            emptyMessage="Não foram encontrados envios."
            responsiveLayout="scroll"
            loadingIcon="pi pi-spin pi-spinner"
          >
            <Column
              field="imagem"
              header="Imagem"
              body={imageTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="campanha"
              header="Campanha"
              sortable
              body={campainTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="titulo"
              header="Título"
              sortable
              body={titleTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="mensagem"
              header="Mensagem"
              sortable
              body={messageTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="link"
              header="Link"
              body={linkTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="data_cadastro"
              header="Data Cadastro"
              sortable
              body={dateTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="dia_agendado"
              header="Dia Agendado"
              sortable
              body={dayTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="hora_agendada"
              header="Horário Agendado"
              sortable
              body={hourTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="status_envio"
              header="Status Envio"
              sortable
              body={statusTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="cliques"
              header="Cliques"
              sortable
              body={clicksTemplate}
              headerStyle={{ width: "14%" }}
            />
            <Column
              field="enviados"
              header="Enviados"
              sortable
              body={sentTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column 
              header="Ações"
              align={'center'}
              body={actionsTemplate}
              headerStyle={{ width: "14%" }}
            />
          </DataTable>

          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  )
}

export default Envios;