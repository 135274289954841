import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// CSS
import "./style.css";

// Componentes
import CardInfo from "./components/CardInfo";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { PushService } from "../../../service/Notificacao/PushService";
import { Toast } from "primereact/toast";
import Exportar from "../../../components/Exportar";
import { Funcoes } from "../../../utils/funcoes";

const Dashboard = () => {

  const toast = useRef(null);
  const dt = useRef(null);

  const utils = new Funcoes();
  const pushService = new PushService();
  
  const history = useHistory();

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  const [indicadores, setIndicadores] = useState([]);

  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const [campanha, setCampanha] = useState(0);
  const [campanhaSelectList, setCampanhaSelectList] = useState([]);

  const [convenio, setConvenio] = useState(0);
  const [convenioSelectList, setConvenioSelectList] = useState([]);

  useEffect(() => {
    if (retornoDado.status === false) {
       
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  const formFields = (
    <>
      <div className="grid gap-5">
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span style={{ fontSize: '16px', color: '#505050' }}>Data Início</span>
          <InputMask
            mask={"De: 99/99/9999"}
            type="text"
            id="form-initial-date"
            value={dataInicial}
            className="text-xl"
            onChange={(data)=>setDataInicial(data.value)}
            placeholder="De: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span style={{ fontSize: '16px', color: '#505050' }}>Data Final</span>
          <InputMask
            mask={"Até: 99/99/9999"}
            type="text"
            id="form-final-date"
            value={dataFinal}
            className="text-xl"
            onChange={(data)=>setDataFinal(data.value)}
            placeholder="Até: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span style={{ fontSize: '16px', color: '#505050' }}>Campanha</span>
          <Dropdown
            id="form-campanha"
            value={campanha} 
            options={campanhaSelectList}
            className="text-xl"
            onChange={(data)=>setCampanha(data.value)}
            placeholder="Selecione..."
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span style={{ fontSize: '16px', color: '#505050' }}>Convênio</span>
          <Dropdown
            id="form-convenio"
            value={convenio} 
            options={convenioSelectList}
            className="text-xl"
            onChange={(data)=>setConvenio(data.value)}
            placeholder="Selecione..."
          />
        </div>
      </div>
    </>
  )

  const buscaIndicadores = () => {

    const inicio = dataInicial ? utils.handleDate(dataInicial) : null;
    const fim = dataFinal ? utils.handleDate(dataFinal) : null;

    setLoadingSpinner(true);
    pushService
      .indicador(inicio, fim, campanha, convenio.toString()).then(
        (data) =>
          data.status === true ? setIndicadores(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error.response.data)
     });

  }

  const limpaFormulario = () => {
    setConvenio(0);
    setCampanha(0);
    setDataInicial(null);
    setDataFinal(null);
  }

  const exportBtn = () => {
    const cols = [
      { field: "Indicador", header: "Indicador" },
      { field: "Referência", header: "Referência" },
      { field: "Dado", header: "Dado" },
    ];

    const dataExport = indicadores.map(indicador => {
      return {
        "Indicador": indicador.indicador,
        "Referência": indicador.subject,
        "Dado": indicador.data,
      }
    })

    return (
      <React.Fragment>
        <Exportar dt={dt} cols={cols} dadosService={dataExport} titulo="Indicadores de Notificação" id="header-export-btn" />
      </React.Fragment>
    );
  };

  const formSubmit = (
    <>
      <div className="flex flex-row gap-2">
        <Button label="Buscar" onClick={buscaIndicadores} id="form-search-btn"></Button>
        <Button label="Limpar" className="p-button-outlined" onClick={limpaFormulario} id="form-clear-btn"></Button>
        {exportBtn()}
      </div>
    </>
  )

  useEffect(()=>{

    setLoadingSpinner(true);
    pushService
      .getCampanhasList().then(
        (data) =>
          data.status === true ? setCampanhaSelectList(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
     });

    setLoadingSpinner(true);
    pushService
      .getConveniosList().then(
        (data) =>
          data.status === true ? setConvenioSelectList(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
     });

    buscaIndicadores();

  }, [])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card pt-5 pl-5 pr-8">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={formFields}
            right={formSubmit}
          />
          <div className="cardPainel">
            {
              indicadores &&
                indicadores.map((indicador, key) => {
        
                  return <>
                    <div key={key} className="col-12 md:col-4 lg:col-3">
                      <CardInfo
                        value={indicador.data}
                        secondaryText={indicador.indicador}
                        opitionalText={indicador.subject ?? ""}
                      />
                    </div>
                  </>
            
                })
            }
          </div>
        </div>
      </div>
      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

export default Dashboard;
