import api from "../Api";

export class CartaoVirtualService {
 
  async postBloqueiaApp(id,cav_bloqueado,cav_motivo_bloqueio,token) {
    return await api
      .post(
        "administrativo/servidor/bloquear-app",
        { 
          cav_id: id,
          cav_bloqueado:cav_bloqueado,
          cav_motivo_bloqueio:cav_motivo_bloqueio,
          tokenGoogleo:token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
 
 
  async postCancelaServidor(id,cav_cancelado,cav_motivo,token) {
    return await api
      .post(
        "administrativo/servidor/cancelar-servidor",
        { 
          cav_id: id,
          cav_cancelado:cav_cancelado,
          cav_motivo:cav_motivo,
          tokenGoogleo:token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getEstadoCivil() {
    return await api
      .get("administrativo/servidor/estadocivil", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getTipoDocPessoa() {
    return await api
      .get("administrativo/servidor/tipodocpessoa", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getCategoria() {
    return await api
      .get("administrativo/servidor/listar-categoria", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getOrgao() {
    return await api
      .get("administrativo/servidor/listar-orgao", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getEstabelecimento() {
    return await api
      .get("administrativo/servidor/listar-estabelecimento", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getAll() {
    return await api
      .get("administrativo/servidor/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "administrativo/servidor/visualizar",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getByCpf(cpf, token) {
    return await api
      .post(
        "administrativo/servidor/pesquisarCpf",
        {
          cav_cpf: cpf,
          tokenGoogleo: token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  postBloqueiaCartaoDigital
  async postBloqueiaCartaoDigital(
    cav_id,
    cav_compras_cartao_bloqueio,
    cav_compras_cartao_motivo,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/servidor/bloqueiacartaodigital",
        {
          cav_id: cav_id,
          cav_compras_cartao_bloqueio: cav_compras_cartao_bloqueio,
          cav_compras_cartao_motivo: cav_compras_cartao_motivo,
          tokenGoogleo: tokenGoogleo
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async getResgateById(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentosresgate",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getDownloadById(id) {
    return await api
      .post(
        "administrativo/servidor/download-averbacao",
        { tra_id: id },
        {
          headers: {            
            "Content-type": "application/json",         
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getResgateEmprestimoById(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentosresgateemprestimo",
        { cav_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getDowloadFileByName(nomeArquivo) {
    return await api
      .post(
          "administrativo/servidor/download-file",
          { tep_nome_arquivo: nomeArquivo },
          {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
      )
      .then((res) => res.data)
  }

  async postCadastrar(
    cav_ope_id,
    cav_codigo_convenio,
    cav_cpf,
    cav_matricula,
    cav_nome,
    cav_nome_mae,
    cav_nome_pai,
    cav_sexo,
    cav_data_nascimento_,
    cav_ecv_id,
    cav_nacionalidade,
    cav_rg_numero,
    cav_tdc_id,
    cav_documento_uf,
    cav_rg_orgao_emissor,
    cav_rg_data_emissao_,
    cav_celular,
    cav_email,
    cav_endereco,
    cav_bairroendereco,
    cav_cep,
    cav_cidadeendereco,
    cav_complementoendereco,
    cav_endereconumero,
    cav_estadoendereco,
    cav_valor_renda,
    cav_endereco_tipo,
    cav_cancelado,
    cav_motivo,
    cav_bloqueado,
    cav_motivo_bloqueio,
    cav_ban_id,
    cav_ban_agencia,
    cav_ban_conta,
    cav_ban_digito,
    cav_ctg_id,
    cav_zee_id,
    cav_zeo_id,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/servidor/cadastrar",
        {

          cav_ope_id: cav_ope_id,
          cav_codigo_convenio: cav_codigo_convenio,
          cav_cpf: cav_cpf,
          cav_matricula: cav_matricula,
          cav_nome: cav_nome,
          cav_nome_mae: cav_nome_mae,
          cav_nome_pai: cav_nome_pai,
          cav_sexo: cav_sexo,
          cav_data_nascimento_: cav_data_nascimento_,
          cav_ecv_id: cav_ecv_id,
          cav_nacionalidade: cav_nacionalidade,
          cav_rg_numero: cav_rg_numero,
          cav_tdc_id: cav_tdc_id,
          cav_documento_uf: cav_documento_uf,
          cav_rg_orgao_emissor: cav_rg_orgao_emissor,
          cav_rg_data_emissao_: cav_rg_data_emissao_,
          cav_celular: cav_celular,
          cav_email: cav_email,
          cav_endereco: cav_endereco,
          cav_bairroendereco: cav_bairroendereco,
          cav_cep: cav_cep,
          cav_cidadeendereco: cav_cidadeendereco,
          cav_complementoendereco: cav_complementoendereco,
          cav_endereconumero: cav_endereconumero,
          cav_estadoendereco: cav_estadoendereco,
          cav_valor_renda: cav_valor_renda,
          cav_endereco_tipo: cav_endereco_tipo,
          cav_cancelado: cav_cancelado,
          cav_motivo: cav_motivo,
          cav_bloqueado: cav_bloqueado,
          cav_motivo_bloqueio: cav_motivo_bloqueio,
          cav_ban_id: cav_ban_id,
          cav_ban_agencia: cav_ban_agencia,
          cav_ban_conta: cav_ban_conta,
          cav_ban_digito: cav_ban_digito,
          cav_ctg_id: cav_ctg_id,
          cav_zee_id: cav_zee_id,
          cav_zeo_id: cav_zeo_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }



  async postAtualizar(
    cav_id,
    cav_ope_id,
    cav_cpf,
    cav_matricula,
    cav_nome,
    cav_nome_mae,
    cav_nome_pai,
    cav_sexo,
    cav_data_nascimento_,
    cav_ecv_id,
    cav_nacionalidade,
    cav_rg_numero,
    cav_tdc_id,
    cav_documento_uf,
    cav_rg_orgao_emissor,
    cav_rg_data_emissao_,
    cav_celular,
    cav_email,
    cav_endereco,
    cav_bairroendereco,
    cav_cep,
    cav_cidadeendereco,
    cav_complementoendereco,
    cav_endereconumero,
    cav_estadoendereco,
    cav_valor_renda,
    cav_endereco_tipo,     
    cav_ban_id,
    cav_ban_agencia,
    cav_ban_conta,
    cav_ban_digito,
    cav_ctg_id,
    cav_zee_id,
    cav_zeo_id,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/servidor/atualizar",
        {
          cav_id: cav_id,
          cav_ope_id: cav_ope_id,
          cav_cpf: cav_cpf,
          cav_matricula: cav_matricula,
          cav_nome: cav_nome,
          cav_nome_mae: cav_nome_mae,
          cav_nome_pai: cav_nome_pai,
          cav_sexo: cav_sexo,
          cav_data_nascimento_: cav_data_nascimento_,
          cav_ecv_id: cav_ecv_id,
          cav_nacionalidade: cav_nacionalidade,
          cav_rg_numero: cav_rg_numero,
          cav_tdc_id: cav_tdc_id,
          cav_documento_uf: cav_documento_uf,
          cav_rg_orgao_emissor: cav_rg_orgao_emissor,
          cav_rg_data_emissao_: cav_rg_data_emissao_,
          cav_celular: cav_celular,
          cav_email: cav_email,
          cav_endereco: cav_endereco,
          cav_bairroendereco: cav_bairroendereco,
          cav_cep: cav_cep,
          cav_cidadeendereco: cav_cidadeendereco,
          cav_complementoendereco: cav_complementoendereco,
          cav_endereconumero: cav_endereconumero,
          cav_estadoendereco: cav_estadoendereco,
          cav_valor_renda: cav_valor_renda,
          cav_endereco_tipo: cav_endereco_tipo,          
          cav_ban_id: cav_ban_id,
          cav_ban_agencia: cav_ban_agencia,
          cav_ban_conta: cav_ban_conta,
          cav_ban_digito: cav_ban_digito,
          cav_ctg_id: cav_ctg_id,
          cav_zee_id: cav_zee_id,
          cav_zeo_id: cav_zeo_id,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async planosEmprestimoParcelasServidor(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentosparcelas",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async assinaturaParcelasServidor(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentosparcelas",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async planosEmprestimoVisualizarParcelaServidor(id) {
    return await api
      .post(
        "administrativo/servidor/visualizarparcela",
        {
          tep_id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async assinaturaVisualizarParcelaServidor(id) {
    return await api
      .post(
        "administrativo/servidor/visualizarparcela",
        {
          tep_id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async planosEmprestimoAtualizarParcelaServidor(tep_id,tep_motivo,tep_status,arquivo) {
    return await api
      .post(
        "administrativo/servidor/atualizarparcelas",
        {
          tep_id,
          tep_motivo,
          tep_status,
          arquivo
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async assinaturasAtualizarParcelaServidor(id,motivo,status) {
    return await api
      .post(
        "administrativo/servidor/atualizarparcelas",
        {
          tep_id: id,
          tep_motivo: motivo,
          tep_status: status 
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async planosEmprestimoServidor(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentosplanos",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async lancamentosServidor(id) {
    return await api
      .post(
        "administrativo/servidor/lancamentos",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async assinaturaServidor(id) {
    return await api
      .post(
        "administrativo/servidor/assinaturas",
        {
          id: id
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postResgatarMargem(
    cav_id,
    valor,
    arquivo,
    tokenGoogleo
  ) {
    const formData = new FormData();
    formData.append('cav_id', cav_id);
    formData.append('valor', valor);
    formData.append('arquivo', arquivo);
    formData.append('tokenGoogleo', tokenGoogleo);

    return await api
      .post(
        "administrativo/servidor/resgatarmargem",
        formData
        ,
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postResgatarEmprestimo(
    cav_id,
    valor,
    arquivo,
    qtdeParcelas,
    tokenGoogleo
  ) {     
    return await api
      .post(
        "administrativo/servidor/resgataremprestimo",
        {
          cav_id,
          valor,
          arquivo,
          qtdeParcelas,
          tokenGoogleo
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async postCancelarMargem(
    cav_id,
    valor,
    tokenGoogleo
  ) {
    return await api
      .post(
        "administrativo/servidor/cancelarmargem",
        {
          cav_id: cav_id,
          valor,
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

}
