import React, { useEffect, useRef, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import { PushService } from "../../../../service/Notificacao/PushService";

import LoadingSpinner from "../../../../components/LoadingSpinner";
import { Toast } from "primereact/toast";

const Select = () => {

    const buttonStyle = {
        paddingTop: 120,
        paddingBottom: 120,
        paddingRight: 50,
        paddingLeft: 50,
        fontSize: 24,
        fontWeight: 'bold',
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: 20,
        boxShadow: "4px 4px 5px 0px grey",
        borderWidth: 0,
        cursor: 'pointer',
        width: 400
    }

    const toast = useRef(null);
    const history = useHistory();

    const pushService = new PushService();

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [retornoDado, setRetornoDado] = useState({
        status: null,
        msg: "",
    });
    
    const handleClickUseNotification = () => {
        setLoadingSpinner(true);
        pushService
            .getCampanhasList().then(
                (data) => 
                data.status === true && data.dados.length > 0 ? history.push("/notificacao/criar/1/0") : setRetornoDado({status: false, msg: "Não há notificações existentes, clique para criar uma nova notificação."}),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                history.push("/semToken");
                console.error(error)
            });
    }

    useEffect(() => {
        if (retornoDado.status === false) {
           
            toast.current.show({
                severity: "error",
                summary: retornoDado.mensagem,
                life: 3000,
            });
        }
    }, [retornoDado]);


    return (
        <>
            <Toast ref={toast} />
            <div className="flex justify-content-around align-items-center" style={{height: '75vh'}}>
                <div className="flex flex-column">
                    <div className="flex flex-row justify-content-around" style={{gap: 200}}>

                        <button style={buttonStyle} onClick={handleClickUseNotification}>
                            Usar notificação existente
                        </button>

                        <Link to={"/notificacao/criar/0/0"}>
                            <button style={buttonStyle}>
                                Criar nova notificação
                            </button>
                        </Link>

                    </div>

                </div>
            </div>
            <LoadingSpinner visualiza={loadingSpinner} />
        </>
    )
}

export default Select;