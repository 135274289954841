import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";


import { AppTopbarPrisma } from "./AppTopbarPrisma";
import { AppFooterPrisma } from "./AppFooterPrisma";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import EmptyPage from "./pages/EmptyPage";
import TimelineDemo from "./pages/TimelineDemo";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";

import Dashboard from "./pages/Dashboard/_Index";
import AgendaCorte from "./pages/AgendaCorte/_Index";
import AgendaCorte_Form from "./pages/AgendaCorte/_Form";



import Correspondente from "./pages/CartaoVirtual/Correspondente/_Index";
import Correspondente_Form from "./pages/CartaoVirtual/Correspondente/_Form";
import CorrespondenteResgate_Form from "./pages/CartaoVirtual/Correspondente/CorrespodenteResgate_Form";
import CorrespondenteEmprestimo_Index from "./pages/CartaoVirtual/Correspondente/CorrespodentePlanosEmprestimo_Index";
import CorrespondenteEmprestimo_Form from "./pages/CartaoVirtual/Correspondente/CorrespodenteEmprestimo_Form";
import CorrespondenteLancamentos_Index from "./pages/CartaoVirtual/Correspondente/CorrespodenteLancamentos_Index";
import CorrespondenteRelLancamentos_Index from "./pages/CartaoVirtual/Correspondente/CorrespondenteRelEmprestimo_Index";

import Servidor from "./pages/CartaoVirtual/Servidor/_Index";
import Servidor_Form from "./pages/CartaoVirtual/Servidor/_Form";
import ServidorResgate_Form from "./pages/CartaoVirtual/Servidor/Resgate_Form";
import ServidorResgateEmprestimo_Form from "./pages/CartaoVirtual/Servidor/ResgateEmprestimo_Form";
import ServidorResgateCancelar_Form from "./pages/CartaoVirtual/Servidor/Resgate_Cancelar_Form";
import ServidorEmprestimo_Index from "./pages/CartaoVirtual/Servidor/PlanosEmprestimo_Index";
import ServidorEmprestimoParcelas_Index from "./pages/CartaoVirtual/Servidor/PlanosEmprestimoParcelas_Index";
import ServidorLancamentos_Index from "./pages/CartaoVirtual/Servidor/Lancamentos_Index";

import Planos_Seguro_Form from "./pages/Seguros/_Form";

//import CartaoVirtual_PlanosEmprestimo from "./pages/CartaoVirtual/PlanosEmprestimo_Index";

import TipoEstabelecimento from "./pages/TipoEstabelecimento/_Index";
import TipoEstabelecimento_Form from "./pages/TipoEstabelecimento/_Form";

import Convenio from "./pages/Convenio/_Index";
import Convenio_Form from "./pages/Convenio/_Form";
import LogApp from "./pages/LogApp/_Index";
import LogApp_Form from "./pages/LogApp/_Form";

import LogSistema from "./pages/LogSistema/_Index";
import LogSistema_Form from "./pages/LogSistema/_Form";

import Perfil from "./pages/Perfil/_Index";
import Perfil_Form from "./pages/Perfil/_Form";

import Estabelecimentos from "./pages/Estabelecimentos/_Index";
import Estabelecimentos_Form from "./pages/Estabelecimentos/_Form";
import Processadoras from "./pages/Estabelecimentos/Processadoras/_Index";
import Processadoras_Form from "./pages/Estabelecimentos/Processadoras/_Form";


import Feriados from "./pages/Feriados/_Index";
import Feriados_Form from "./pages/Feriados/_Form";

import Usuarios from "./pages/Usuarios/_Index";
import Usuarios_Form from "./pages/Usuarios/_Form";

import Informativo from "./pages/Informativo/_Index";
import Informativo_Form from "./pages/Informativo/_Form";

import Metas from "./pages/Metas/_Index";
import Metas_Form from "./pages/Metas/_Form";

import TipoDePlano from "./pages/TipoPlanos/_Index";
import TipoDePlano_Form from "./pages/TipoPlanos/_Form";

import Parametros from "./pages/Parametros/_Index";

import SemAcesso from "./pages/SemAcesso/_Index";
import SemToken from "./pages/SemToken/_Index";

import RelAplicativo from "./pages/Relatorios/RelAplicativo_Index";
import RelCompra from "./pages/Relatorios/RelCompra_Index";
import RelCartao from "./pages/Relatorios/RelCartao_Index";
import RelCartaoSolicitados from "./pages/Relatorios/RelCartaoSolicitados_Index";
import RelEmprestimo from "./pages/Relatorios/RelEmprestimo_Index";

import Contrato from "./pages/Contrato/_Index";
import Contrato_Form from "./pages/Contrato/_Form";

import ContratoLog from "./pages/ContratoLog/_Form";

import LogEstatistico from "./pages/LogEstatistico/_Index";
import LogEstatistico_Form from "./pages/LogEstatistico/_Form";

import SegurosSulAmerica from "./pages/SegurosSulAmerica/_Index";
import SegurosSulAmerica_Form from "./pages/SegurosSulAmerica/_Form";

import Repasse from "./pages/Repasse/_Index";
import Repasse_Form from "./pages/Repasse/_Form";

import LogDoApp from "./pages/LogEstatisticoApp/_Index";
import LogDoApp_Form from "./pages/LogEstatisticoApp/_Form";


// import Usuarios from "./pages/Usuarios/_Index"
// import Usuarios_Form from "./pages/Usuarios/_Form";

import Simular_Form from "./pages/Simular/_Form";



const AppMain = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE}`,
      function () { }
    );
  }, []);

  const permissaoAcesso = (num) => {
    const teste = sessionStorage
      .getItem("acessos")
      .replace('"', "")
      .replace('"', "");

    if (!teste) {
      return null;
    } else {
      const acessos = teste.trim().split(",");
      let achou = acessos.find((item) => item === num);
      return achou === undefined ? true : null;
    }
  };

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      items: [
        {
          label: "Administrativo",
          icon: "pi pi-fw pi-home",
          to: "/aba-administrativo",
          items: [
            {
              label: "Perfil",
              icon: "pi pi-lock-open",
              to: "/perfil",
              disabled: permissaoAcesso("adm/perfil/acessar"),
            },
            {
              label: "Usuários",
              icon: "pi pi-lock-open",
              disabled: permissaoAcesso("adm/usuarios/acessar"),
              to: "/usuarios",
            },
            {
              label: "Servidor",
              icon: "pi pi-lock-open",
              to: "/servidor",
              disabled: permissaoAcesso("adm/servidor/acessar"),
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: "Operacional",
          icon: "pi pi-fw pi-home",
          to: "/aba-operacional",
          items: [
            {
              label: "Cadastro de Cliente",
              icon: "pi pi-lock-open",
              to: "/correspondente",
              disabled: permissaoAcesso("corr/servidor/acessar"),
            },
            // {
            //   label: "Relatórios",
            //   icon: "pi pi-fw pi-home",
            //   items: [
            //     {
            //       label: "Empréstimos",
            //       icon: "pi pi-lock-open",
            //       to: "/correspondenteRelEmprestimo",
            //       disabled: permissaoAcesso("corr/relatorio/emprestimos/acessar"),
            //     },
            //   ],
            // },
            {
              label: "Simulador",
              icon: "pi pi-lock-open",
              to: "/simular",
              disabled: permissaoAcesso("corr/simular/acessar"),
            },
            {
              label: "Amortização",
              icon: "pi pi-lock-open",
              to: "/subaba-amortizacao",
              items: [

              ],
            },
            {
              label: "Esteira",
              icon: "pi pi-lock-open",
              to: "/esteira",
              disabled: permissaoAcesso("corr/simular/acessar"),
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: "Gerencial",
          icon: "pi pi-fw pi-home",
          to: "/aba-gerencial",
          items: [             
            {
              label: "Relatórios",
              icon: "pi pi-fw pi-home",
              items: [
                {
                  label: "Aplicativo",
                  icon: "pi pi-lock-open",
                  to: "/relAplicativo",
                  disabled: permissaoAcesso("ger/relatorio/aplicativo/acessar"),
                },
                {
                  label: "Seguros",
                  icon: "pi pi-lock-open",
                  to: "/relCompra",
                  disabled: permissaoAcesso("ger/relatorio/compra/acessar"),
                },
                {
                  label: "Cartão Benefícios",
                  icon: "pi pi-fw pi-home",
                  items: [
                    {
                      label: "Lançamentos",
                      icon: "pi pi-lock-open",
                      to: "/relCartao",
                      disabled: permissaoAcesso("ger/relatorio/cartaolancamentos/acessar"),
                    },
                    {
                      label: "Solicitações e cancelamentos",
                      icon: "pi pi-lock-open",
                      to: "/cartaoSolicitadosCancelados",
                      disabled: permissaoAcesso("ger/relatorio/cartaosolicitadoscancelados/acessar"),
                    },]
                  },
                {
                  label: "Empréstimos",
                  icon: "pi pi-lock-open",
                  to: "/relEmprestimo/0",
                  disabled: permissaoAcesso("ger/relatorio/emprestimo/acessar"),
                },
              ],
            },                    
          ],
        },
      ],
    },

  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbarPrisma
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />
      <Router>
        <div className="layout-sidebar" onClick={onSidebarClick}>
          <AppMenu
            model={menu}
            onMenuItemClick={onMenuItemClick}
            layoutColorMode={layoutColorMode}
          />
        </div>

        <div className="layout-main-container">
          <div className="layout-main">
            <Switch>

              <Route path="/empty" component={Dashboard} />
              <Route path="/timeline" component={Dashboard} />

              <Route path="/dashboard/" exact component={Dashboard} />

              <Route
                path="/AgendaCorteIndex/:id_convenio/:id_operadora/"
                component={
                  !permissaoAcesso("adm/agenda/acessar")
                    ? AgendaCorte
                    : SemAcesso
                }
              />
              <Route
                path="/agendaCorteForm_I/:id/:id_convenio/:id_operadora/:tipo"
                component={
                  !permissaoAcesso("adm/agenda/incluir")
                    ? AgendaCorte_Form
                    : SemAcesso
                }
              />
              <Route
                path="/agendaCorteForm_U/:id/:id_convenio/:id_operadora/:tipo"
                component={
                  !permissaoAcesso("adm/agenda/alterar")
                    ? AgendaCorte_Form
                    : SemAcesso
                }
              />
              <Route
                path="/agendaCorteForm_V/:id/:id_convenio/:id_operadora/:tipo"
                component={
                  !permissaoAcesso("adm/agenda/visualizar")
                    ? AgendaCorte_Form
                    : SemAcesso
                }
              />

              <Route
                path="/FeriadosIndex/:id_convenio/:id_operadora/"
                component={
                  !permissaoAcesso("adm/feriados/acessar")
                    ? Feriados
                    : SemAcesso
                }
              />
              <Route
                path="/feriadosForm_I/:id/:id_convenio/:tipo"
                component={
                  !permissaoAcesso("adm/feriados/incluir")
                    ? Feriados_Form
                    : SemAcesso
                }
              />
              <Route
                path="/feriadosForm_U/:id/:id_convenio/:tipo"
                component={
                  !permissaoAcesso("adm/feriados/alterar")
                    ? Feriados_Form
                    : SemAcesso
                }
              />
              <Route
                path="/feriadosForm_V/:id/:id_convenio/:tipo"
                component={
                  !permissaoAcesso("adm/feriados/visualizar")
                    ? Feriados_Form
                    : SemAcesso
                }
              />

              <Route
                path="/correspondente"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? Correspondente
                    : SemAcesso
                }
              />
              <Route
                path="/correspondenteForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("corr/servidor/incluir")
                    ? Correspondente_Form
                    : SemAcesso
                }
              />
              <Route
                path="/correspondenteForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("corr/servidor/alterar")
                    ? Correspondente_Form
                    : SemAcesso
                }
              />
              <Route
                path="/correspondenteForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("corr/servidor/visualizar")
                    ? Correspondente_Form
                    : SemAcesso
                }
              />

              <Route
                path="/correspondenteForm_P/:id"
                component={
                  !permissaoAcesso("corr/servidor/seguros")
                    ? Planos_Seguro_Form
                    : SemAcesso
                }
              />


              <Route
                path="/correspondenteResgate_form/:id"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? CorrespondenteResgate_Form
                    : SemAcesso
                }
              />

              <Route
                path="/correspondenteEmprestimo_form/:id"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? CorrespondenteEmprestimo_Form
                    : SemAcesso
                }
              />

              <Route
                path="/correspondenteEmprestimo_Index/:id"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? CorrespondenteEmprestimo_Index
                    : SemAcesso
                }
              />


              <Route
                path="/correspondenteLancamentos_Index/:id"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? CorrespondenteLancamentos_Index
                    : SemAcesso
                }
              />

              <Route
                path="/correspondenteRelEmprestimo"
                component={
                  !permissaoAcesso("corr/relatorio/emprestimos/acessar")
                    ? CorrespondenteRelLancamentos_Index
                    : SemAcesso
                }
              />


              <Route
                path="/simular"
                component={
                  !permissaoAcesso("corr/simular/acessar")
                    ? Simular_Form
                    : SemAcesso
                }
              />


              <Route
                path="/servidor"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? Servidor
                    : SemAcesso
                }
              />
              <Route
                path="/servidorLancamentos_Index/:id"
                component={
                  !permissaoAcesso("corr/servidor/acessar")
                    ? CorrespondenteLancamentos_Index
                    : SemAcesso
                }
              />

              <Route
                path="/servidorForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/servidor/incluir")
                    ? Servidor_Form
                    : SemAcesso
                }
              />
              <Route
                path="/servidorForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/servidor/alterar")
                    ? Servidor_Form
                    : SemAcesso
                }
              />
              <Route
                path="/servidorForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/servidor/visualizar")
                    ? Servidor_Form
                    : SemAcesso
                }
              />

              <Route
                path="/servidorForm_P/:id/:tipo"
                component={
                  !permissaoAcesso("adm/servidor/seguros")
                    ? Planos_Seguro_Form
                    : SemAcesso
                }
              />

              <Route
                path="/planosEmprestimoIndex/:id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorEmprestimo_Index
                    : SemAcesso
                }
              />

              <Route
                path="/planosEmprestimoParcelasIndex/:id/:trr_id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorEmprestimoParcelas_Index
                    : SemAcesso
                }
              />

              <Route
                path="/resgate_form/:id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorResgate_Form
                    : SemAcesso
                }
              />

              <Route
                path="/resgateemprestimo_form/:id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorResgateEmprestimo_Form
                    : SemAcesso
                }
              />

              <Route
                path="/resgate_cancelar_form/:id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorResgateCancelar_Form
                    : SemAcesso
                }
              />


              <Route
                path="/lancamentosIndex/:id"
                component={
                  !permissaoAcesso("adm/servidor/acessar")
                    ? ServidorLancamentos_Index
                    : SemAcesso
                }
              />



              <Route
                path="/perfil"
                component={
                  !permissaoAcesso("adm/perfil/acessar") ? Perfil : SemAcesso
                }
              />
              <Route
                path="/perfilForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/incluir")
                    ? Perfil_Form
                    : SemAcesso
                }
              />
              <Route
                path="/perfilForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/alterar")
                    ? Perfil_Form
                    : SemAcesso
                }
              />
              <Route
                path="/perfilForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/perfil/visualizar")
                    ? Perfil_Form
                    : SemAcesso
                }
              />

              <Route
                path="/convenio"
                component={
                  !permissaoAcesso("adm/convenio/acessar")
                    ? Convenio
                    : SemAcesso
                }
              />
              <Route
                path="/convenioForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/convenio/alterar")
                    ? Convenio_Form
                    : SemAcesso
                }
              />
              <Route
                path="/convenioForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/convenio/visualizar")
                    ? Convenio_Form
                    : SemAcesso
                }
              />

              <Route
                path="/TipoEstabelecimento"
                component={
                  !permissaoAcesso("adm/tipos/estabelecimento/acessar")
                    ? TipoEstabelecimento
                    : SemAcesso
                }
              />
              <Route
                path="/tipoEstabelecimentoForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/estabelecimento/incluir")
                    ? TipoEstabelecimento_Form
                    : SemAcesso
                }
              />
              <Route
                path="/tipoEstabelecimentoForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/estabelecimento/alterar")
                    ? TipoEstabelecimento_Form
                    : SemAcesso
                }
              />
              <Route
                path="/tipoEstabelecimentoForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/estabelecimento/visualizar")
                    ? TipoEstabelecimento_Form
                    : SemAcesso
                }
              />
              <Route
                path="/logApp"
                component={
                  !permissaoAcesso("adm/log/logApp/acessar")
                    ? LogApp
                    : SemAcesso
                }
              />
              <Route
                path="/logAppForm_V/:nome/:id/:tipo"
                component={
                  !permissaoAcesso("adm/log/logApp/visualizar")
                    ? LogApp_Form
                    : SemAcesso
                }
              />

              <Route
                path="/logSistema"
                component={
                  !permissaoAcesso("adm/log/logSistema/acessar")
                    ? LogSistema
                    : SemAcesso
                }
              />
              <Route
                path="/logSistemaForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/log/logSistema/visualizar")
                    ? LogSistema_Form
                    : SemAcesso
                }
              />

              <Route
                path="/logEstatistico"
                component={
                  !permissaoAcesso("adm/log/logEstatistico/acessar")
                    ? LogEstatistico
                    : SemAcesso
                }
              />
              <Route
                path="/logEstatisticoForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/log/logEstatistico/visualizar")
                    ? LogEstatistico_Form
                    : SemAcesso
                }
              />

              <Route
                path="/logDoAplicativo"
                component={
                  !permissaoAcesso("adm/log/logDoAplicativo/acessar")
                    ? LogDoApp
                    : SemAcesso
                }
              />
              <Route
                path="/logDoAplicativo_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/log/logDoAplicativo/visualizar")
                    ? LogDoApp_Form
                    : SemAcesso
                }
              />
              <Route
                path="/Estabelecimentos"
                component={
                  !permissaoAcesso("adm/estabelecimento/acessar")
                    ? Estabelecimentos
                    : SemAcesso
                }
              />
              <Route
                path="/estabelecimentosForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/estabelecimento/incluir")
                    ? Estabelecimentos_Form
                    : SemAcesso
                }
              />
              <Route
                path="/estabelecimentosForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/estabelecimento/alterar")
                    ? Estabelecimentos_Form
                    : SemAcesso
                }
              />
              <Route
                path="/estabelecimentosForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/estabelecimento/visualizar")
                    ? Estabelecimentos_Form
                    : SemAcesso
                }
              />
              <Route
                path="/Processadoras"
                component={
                  !permissaoAcesso("adm/processadora/acessar")
                    ? Processadoras
                    : SemAcesso
                }
              />
              <Route
                path="/processadorasForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/processadora/incluir")
                    ? Processadoras_Form
                    : SemAcesso
                }
              />
              <Route
                path="/processadorasForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/processadora/alterar")
                    ? Processadoras_Form
                    : SemAcesso
                }
              />
              <Route
                path="/processadorasForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/processadora/visualizar")
                    ? Processadoras_Form
                    : SemAcesso
                }
              />





              <Route
                path="/usuarios"
                component={
                  !permissaoAcesso("adm/usuarios/acessar")
                    ? Usuarios
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/incluir")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/alterar")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              <Route
                path="/usuariosForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/usuarios/visualizar")
                    ? Usuarios_Form
                    : SemAcesso
                }
              />
              <Route
                path="/informativo"
                component={
                  !permissaoAcesso("adm/informativo/acessar")
                    ? Informativo
                    : SemAcesso
                }
              />
              <Route
                path="/informativoForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/informativo/incluir")
                    ? Informativo_Form
                    : SemAcesso
                }
              />
              <Route
                path="/informativoForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/informativo/alterar")
                    ? Informativo_Form
                    : SemAcesso
                }
              />
              <Route
                path="/informativoForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/informativo/visualizar")
                    ? Informativo_Form
                    : SemAcesso
                }
              />

              <Route
                path="/metas"
                component={
                  !permissaoAcesso("adm/metas/acessar") ? Metas : SemAcesso
                }
              />
              <Route
                path="/metasForm_I/:id/:tipo"
                ir
                component={
                  !permissaoAcesso("adm/metas/incluir") ? Metas_Form : SemAcesso
                }
              />
              <Route
                path="/metasForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/metas/alterar") ? Metas_Form : SemAcesso
                }
              />
              <Route
                path="/metasForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/metas/visualizar")
                    ? Metas_Form
                    : SemAcesso
                }
              />

              <Route
                path="/tipoPlano"
                component={
                  !permissaoAcesso("adm/tipos/plano/acessar")
                    ? TipoDePlano
                    : SemAcesso
                }
              />
              <Route
                path="/tipoPlanoForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/plano/incluir")
                    ? TipoDePlano_Form
                    : SemAcesso
                }
              />
              <Route
                path="/tipoPlanoForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/plano/alterar")
                    ? TipoDePlano_Form
                    : SemAcesso
                }
              />
              <Route
                path="/tipoPlanoForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("adm/tipos/plano/visualizar")
                    ? TipoDePlano_Form
                    : SemAcesso
                }
              />
              <Route
                path="/parametros"
                component={
                  !permissaoAcesso("adm/parametros/acessar")
                    ? Parametros
                    : SemAcesso
                }
              />

              <Route
                path="/relCompra"
                component={
                  !permissaoAcesso("ger/relatorio/compra/acessar")
                    ? RelCompra
                    : SemAcesso
                }
              />

              <Route
                path="/relAplicativo"
                component={
                  !permissaoAcesso("ger/relatorio/aplicativo/acessar")
                    ? RelAplicativo
                    : SemAcesso
                }
              />

              <Route
                path="/relCartao"
                component={
                  !permissaoAcesso("ger/relatorio/cartaolancamentos/acessar")
                    ? RelCartao
                    : SemAcesso
                }
              />

              <Route
                path="/cartaoSolicitadosCancelados"
                component={
                  !permissaoAcesso("ger/relatorio/cartaosolicitadoscancelados/acessar")
                    ? RelCartaoSolicitados
                    : SemAcesso
                }
              />

              <Route
                path="/relEmprestimo/0"
                component={
                  !permissaoAcesso("ger/relatorio/emprestimo/acessar")
                    ? RelEmprestimo
                    : SemAcesso
                }
              />

              <Route
                path="/contrato"
                component={
                  !permissaoAcesso("ger/contratos/acessar")
                    ? Contrato
                    : SemAcesso
                }
              />
              <Route
                path="/contratoForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("ger/contratos/incluir")
                    ? Contrato_Form
                    : SemAcesso
                }
              />
              <Route
                path="/contratoForm_U/:id/:tipo"
                component={
                  !permissaoAcesso("ger/contratos/alterar")
                    ? Contrato_Form
                    : SemAcesso
                }
              />
              <Route
                path="/contratoForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("ger/contratos/visualizar")
                    ? Contrato_Form
                    : SemAcesso
                }
              />

              <Route
                path="/contratoLogForm/:id/:tipo"
                component={
                  !permissaoAcesso("ger/contratos/visualizar")
                    ? ContratoLog
                    : SemAcesso
                }
              />

              <Route
                path="/Repasse"
                component={
                  !permissaoAcesso("ger/seguros/SulAmerica/acessar")
                    ? Repasse
                    : Repasse
                }
              />

              <Route
                path="/SegurosSulAmerica"
                component={
                  !permissaoAcesso("ger/seguros/SulAmerica/acessar")
                    ? SegurosSulAmerica
                    : SemAcesso
                }
              />

              <Route
                path="/SegurosSulAmericaForm_V/:id/:tipo"
                component={
                  !permissaoAcesso("ger/seguros/SulAmerica/visualizar")
                    ? SegurosSulAmerica_Form
                    : SemAcesso
                }
              />

              <Route
                path="/SegurosSulAmericaForm_I/:id/:tipo"
                component={
                  !permissaoAcesso("ger/seguros/SulAmerica/incluir")
                    ? SegurosSulAmerica_Form
                    : SemAcesso
                }
              />

              <Route path="/semToken" exact component={SemToken} />
              <Route path="/semAcesso" exact component={SemAcesso} />
            </Switch>
          </div>

          <AppFooterPrisma layoutColorMode={layoutColorMode} />
        </div>
      </Router>
      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default AppMain;
