import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Checkbox } from "primereact/checkbox";
import { Toolbar } from "primereact/toolbar";
import { ConfirmDialog } from "primereact/confirmdialog";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";

import { PushService } from "../../../../service/Notificacao/PushService";
import { Funcoes } from "../../../../utils/funcoes";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const Formulario = () => {

    const utils = new Funcoes();
    const pushService = new PushService();

    const toast = useRef(null);
    const previewBannerRef = useRef(null);
    const principalBannerRef = useRef(null);
    const history = useHistory();
    const { tipo, id } = useParams();

    const campainIsSet = id != 0;
    const viewPage = tipo == 2;
    const campainIsSearchable = tipo == 1 && !campainIsSet;

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [retornoDado, setRetornoDado] = useState({
        status: null,
        mensagem: "",
    });

    const [baseCampain, setBaseCampain] = useState(campainIsSet ? id : 0);

    const [campainsList, setCampainsList] = useState([]);
    const [planosList, setPlanosList] = useState([]);
    const [conveniosList, setConveniosList] = useState([]);
    const [produtosList, setProdutosList] = useState([]);
    const [categoriasList, setCategoriasList] = useState([]);
    
    const [convenios, setConvenios] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [campanha, setCampanha] = useState("");
    const [titulo, setTitulo] = useState("");
    const [link, setLink] = useState("");
    const [message, setMessage] = useState("");
    const [calendar, setCalendar] = useState([{counter: 0, date:"", time: ""}]);
    const [previewImage, setPreviewImage] = useState("");
    const [principalImage, setPrincipalImage] = useState("");
    const [sendToEveryone, setSendToEveryone] = useState(true);
    const [sendToAbsent, setSendToAbsent] = useState(false);
    const [minimumAge, setMinimumAge] = useState(0);
    const [maximumAge, setMaximumAge] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [searchMargin, setSearchMargin] = useState(false);
    const [minimumMargin, setMinimumMargin] = useState(0);
    const [maximumMargin, setMaximumMargin] = useState(0);
    const [alcance, setAlcance] = useState(0);

    const [visibleDialog, setVisibleDialog] = useState(false);
    const [isMargemSearchOn, setMargemSearchOn] = useState(false);
    const [alcanceMargem, setAlcanceMargem] = useState([]);
    
    const [blockSaveButton, setBlockSaveButton] = useState(false);

    function fieldsValidation(field) {
        switch (field) {
            case 'Convênio':
                if(convenios.length === 0) return false
                break;
            case 'Produto':
                if(produtos.length === 0) return false
                break;
            case 'Data/Hora':
                if(calendar.length === 0){
                    return false
                } else{
                    let calendario = [];

                    calendar.map((data)=>{
                        if(data.date != '' && data.time != ''){
                            calendario.push(data);
                        }

                        if(!utils.isValidDate(utils.handleDate(data.date))){
                            return false;
                        }

                        if(!utils.isHorarioValido(data.time)){
                            return false;
                        }
                    })

                    if(calendario.length === 0){
                        return false;
                    }
                }

                break;

            default:
                break;
        }

        return true;

    }

    function saveNotification() {

        const fields = [
            'Convênio',
            'Produto',
            'Plano',
            'Nome da Campanha',
            'Titulo',
            'URL',
            'Mensagem',
            'Data/Hora',
            'Banner início',
            'Banner Detalhe',
            'Idade Mínima',
            'Idade Máxima',
            'Categorias',
            'Margem Mínima',
            'Margem Máxima',
            'Alcance',
            'Alcance Margem'
        ];

        fields.forEach(field => {

            if(!fieldsValidation(field)){
                setRetornoDado({status: false, mensagem: `Campo '${field}' inválido.`})
                return false;
            }

        });

        setLoadingSpinner(true);
        setBlockSaveButton(true);
        pushService
            .saveCampainNotification(
                convenios, produtos, planos, campanha, titulo, link, message, calendar, previewImage, principalImage, sendToEveryone, // Obrigatórios 
                sendToAbsent, minimumAge, maximumAge, categorias, searchMargin, minimumMargin, maximumMargin, alcance, alcanceMargem, id // Opicionais
            ).then(
                (data) =>
                data.status === true ? handleSuccessfullReturn(data.mensagem) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });

        return true;
    }

    function sendNotification() {
        setLoadingSpinner(true);

        setBlockSaveButton(true);

        pushService
            .sendNotification(
                convenios, produtos, planos, campanha, titulo, link, message, calendar, previewImage, principalImage, sendToEveryone, // Obrigatórios 
                sendToAbsent, minimumAge, maximumAge, categorias, searchMargin, minimumMargin, maximumMargin, alcance, alcanceMargem, id// Opicionais
            ).then(
                (data) =>
                    data.status ?
                        handleSuccessfullReturn(data.mensagem)
                    :
                        (
                            setRetornoDado(data),
                            setBlockSaveButton(false)
                        ),
                        setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });
    }

    function addToCalendar() {
        setCalendar([...calendar, {counter: calendar.length, date:"", time: ""}]);
    }

    function removeFromCalendar(contador) {
        const filteredItems = calendar.filter(item => item.counter !== contador);

        const updatedItems = filteredItems.map((item, index) => ({
            ...item,
            counter: index,
        }));
      
        setCalendar(updatedItems);
    }

    function changeCalendar(contador, date, time) {
        setCalendar(
            calendar.map(item =>
              item.counter === contador
                ? (
                    date === null 
                    ? { ...item, time: time }
                    : { ...item, date: date }
                )
                : item
            )
        );
    }

    const previewBannerUpload = async (event) => {
        if (!event.files || event.files.length === 0) return;
        
        previewBannerRef.current.clear();

        const file = event.files[0];
        const reader = new FileReader();

        setPreviewImage(null);

        let blob = await fetch(file.objectURL).then(r => r.blob());
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            setPreviewImage(base64data);
        };
    };

    const principalBannerUpload = async (event) => {

        if (!event.files || event.files.length === 0) return;
        
        principalBannerRef.current.clear();

        const file = event.files[0];
        const reader = new FileReader();

        setPrincipalImage(null);

        let blob = await fetch(file.objectURL).then(r => r.blob());
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            setPrincipalImage(base64data);
        };

    }

    const fillForm = (data) => {

        setCampanha(data.cnt_nome);
        setLink(data.cnt_url);
        setMessage(data.cnt_descricao);
        setTitulo(data.cnt_titulo);
        setPreviewImage(data.cnt_imagem_preview);
        setPrincipalImage(data.cnt_imagem_principal);

        setProdutos(data.cnt_produto.map((produto)=>{
            return produto.aps_id;
        }));

        setPlanos(data.cnt_plano.map((plano)=>{
            return plano.ser_id;
        }));

        setConvenios(data.cnt_convenio.map((convenio)=>{
            return convenio.ope_id.toString().padStart(2, '0') + convenio.con_id.toString().padStart(3, '0')
        }));

        if(campainIsSet){

            if(data.calendario.length > 0){
                setCalendar(data.calendario.map((value, index) => {
                    const separatedDate = value.data.split("-");
    
                    return {counter: index, date: separatedDate[2] +"/"+ separatedDate[1] +"/"+ separatedDate[0], time: utils.formataHoraParaHHII(value.hora)}
                }))
            }
            
            setSendToEveryone(data.cnt_todos);

            setMinimumAge(data.cnt_min_idade);
            setMaximumAge(data.cnt_max_idade); 

            if(data.cnt_categoria.length > 0){
                setCategorias(data.cnt_categoria.map((categoria)=>{
                    return categoria.categoria
                }));
            }

            setSendToAbsent(data.cnt_envia_inativo);

            setSearchMargin(data.cnt_envia_margem);
            setMinimumMargin(data.cnt_min_margem);
            setMaximumMargin(data.cnt_max_margem);

            setAlcanceMargem(data.cnt_alcance_margem);

        }

    }

    const handleSuccessfullReturn = (mensagem) => {
        toast.current.show({
            severity: "success",
            summary: mensagem,
            life: 3000,
        })

        setTimeout(() => {
            history.push("/envios")
        }, 3000);
    }

    const handleSearchMargin = () => {
        if(!isMargemSearchOn){
            setMargemSearchOn(true);
            
            toast.current.show({severity:'warn', summary: 'Aviso', detail:'Isso pode demorar alguns minutos', life: 3000});
            pushService
            .getAlcanceMargem(convenios, sendToEveryone, sendToAbsent, minimumAge, maximumAge, categorias, produtos, minimumMargin, maximumMargin).then(
                (data) =>
                data.status === true ? setAlcanceMargem(data.dados) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });

            setMargemSearchOn(false);
        } else{
            toast.current.show({severity:'warn', summary: 'Aviso', detail:'A busca por margem está em andamento no momento', life: 3000});
        }
    }

    useEffect(()=>{ // Atualizar select de convenios/produtos/planos
        setLoadingSpinner(true);
        pushService
            .getConveniosList().then(
            (data) =>
                data.status === true ? setConveniosList(data.dados) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });

        setLoadingSpinner(true);
        pushService
            .getProdutosList().then(
            (data) =>
                data.status === true ? setProdutosList(data.dados) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });

        setLoadingSpinner(true);
            pushService
                .getPlanosList().then(
                (data) =>
                    data.status === true ? setPlanosList(data.dados) : setRetornoDado(data),
                    setLoadingSpinner(false)
                )
                .catch((error) => {
                    // history.push("/semToken");
                    console.error(error)
                });
    }, []);

    useEffect(()=>{ // Atualizar select de campanha
        if(campainIsSearchable){
            setLoadingSpinner(true);
            pushService
                .getCampanhasList().then(
                    (data) =>
                    data.status === true ? setCampainsList(data.dados) : setRetornoDado(data),
                    setLoadingSpinner(false)
                )
                .catch((error) => {
                    // history.push("/semToken");
                    console.error(error)
                });
        }
    }, [campainIsSearchable]);

    useEffect(() => { // Exibir toast baseado na resposta da api
        if (retornoDado.status === false) {
           
            toast.current.show({
                severity: "error",
                summary: retornoDado.mensagem,
                life: 3000,
            });

            setRetornoDado({
                status: null,
                mensagem: "",
            })
        }
    }, [retornoDado]);

    useEffect(()=>{ // Atualizar select de categorias
        setLoadingSpinner(true);
        setCategoriasList([])
        pushService
            .getCategoriasList(convenios).then(
            (data) =>
                data.status === true ? setCategoriasList(data.dados) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                // history.push("/semToken");
                console.error(error)
            });
    }, [convenios]);

    useEffect(()=>{
        pushService
            .getAlcance(convenios, sendToEveryone, sendToAbsent, minimumAge, maximumAge, categorias).then(
            (data) =>
                data.status === true ? setAlcance(data.dados) : setRetornoDado(data),
                setLoadingSpinner(false)
            )
            .catch((error) => {
                console.error(error)
            });

    }, [convenios, sendToEveryone, sendToAbsent, minimumAge, maximumAge, categorias]);

    useEffect(()=>{ // Buscar dados da campanha selecionada
        if(baseCampain != 0){
            setLoadingSpinner(true);
            pushService
                .getPushById(baseCampain).then(
                (data) =>
                    data.status === true ? fillForm(data.dados) : setRetornoDado(data),
                    setLoadingSpinner(false)
                )
                .catch((error) => {
                    // history.push("/semToken");
                    console.error(error)
                });
        }
    }, [baseCampain]);

    return (
        <>
            <div className="card p-fluid flex flex-row justify-content-between">
                <Toast ref={toast} />
                <div className="flex flex-column gap-5" style={{width: "54%"}}>
                    <p className="font-bold text-xl pt-2 pl-3">Criar Notificação</p>
                    <div className="grid justify-content-between p-2 gap-5">

                        <div className="col flex flex-column w-full gap-4" style={{maxWidth: '48%'}}>
                            
                            {
                                campainIsSearchable &&
                                <div className="flex flex-column gap-2">
                                    <span for="form-dropdown-base-campain">Selecionar campanha existente</span>
                                    <Dropdown 
                                        value={baseCampain} 
                                        options={campainsList}
                                        onChange={(e)=> {
                                            setBaseCampain(e.target.value)
                                        }} 
                                        optionLabel="label" 
                                        filter 
                                        filterBy="label" 
                                        placeholder="Selecione uma campanha"
                                        id="form-dropdown-base-campain"
                                        disabled={viewPage}
                                    />
                                </div>
                            }
                            
                            <div className="flex flex-column gap-2">
                                <span for="form-dropdown-convenio">Convênio</span>
                                <MultiSelect 
                                    value={convenios} 
                                    options={conveniosList} 
                                    onChange={(e)=>setConvenios(e.target.value)} 
                                    optionLabel="label" 
                                    placeholder="Selecione os Convênios" 
                                    display="chip" 
                                    id="form-dropdown-convenio"
                                    disabled={viewPage}
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-dropdown-produto">Produto</span>
                                <MultiSelect 
                                    value={produtos} 
                                    options={produtosList} 
                                    onChange={(e)=>setProdutos(e.target.value)} 
                                    optionLabel="label" 
                                    placeholder="Selecione os Produtos" 
                                    display="chip" 
                                    id="form-dropdown-produto"
                                    disabled={viewPage}
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-dropdown-plano">Planos</span>
                                <MultiSelect 
                                    value={planos} 
                                    options={planosList} 
                                    onChange={(e)=>setPlanos(e.target.value)} 
                                    optionLabel="label" 
                                    placeholder="Selecione os Planos" 
                                    display="chip" 
                                    id="form-dropdown-plano"
                                    disabled={viewPage}
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-input-name">Nome da Campanha</span>
                                <InputText id="form-input-name" onChange={(e)=>setCampanha(e.target.value)} value={campanha} disabled={viewPage} maxLength={40}/>
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-input-name">Título</span>
                                <InputText id="form-input-name" onChange={(e)=>setTitulo(e.target.value)} value={titulo} disabled={viewPage} maxLength={40}/>
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-input-url">URL</span>
                                <InputText id="form-input-url" onChange={(e)=>setLink(e.target.value)} value={link} disabled={viewPage}/>
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-input-message">Mensagem</span>
                                <InputTextarea id="form-input-message" onChange={(e)=>setMessage(e.target.value)} value={message} autoResize disabled={viewPage} maxLength={100}/>
                                <p className="text-right pr-2 text-sm">Limite de 100 caracteres</p>
                            </div>

                        </div>

                        <div className="col flex flex-column w-full gap-4" style={{maxWidth: '48%'}}>
                            
                            <div className="flex flex-column gap-2">
                                <div className="grid gap-2 px-3">
                                    <span className="col">Data</span>
                                    <span className="col">Hora</span>            
                                </div>
                                {
                                    calendar.map((key)=>{
                                        return <>
                                            <div className="grid gap-2">
                                                <div className="col">
                                                    <InputMask
                                                        mask="99/99/9999"
                                                        value={key.date}
                                                        onChange={(e) => changeCalendar(key.counter, e.target.value, null)}
                                                        id={"form-input-calendar-date-" + key.counter}
                                                        style={{background: "#00000010"}}
                                                        className="text-center"
                                                        disabled={viewPage}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <InputMask
                                                        mask="99:99"
                                                        value={key.time}
                                                        onChange={(e) => changeCalendar(key.counter, null, e.target.value)}
                                                        id={"form-input-calendar-time-" + key.counter}
                                                        style={{background: "#00000010"}}
                                                        className="text-center"
                                                        disabled={viewPage}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Button 
                                                        icon={key.counter === 0 ? "pi pi-plus" : "pi pi-times"} 
                                                        className={key.counter === 0 ? "p-button-rounded p-button-success p-button-outlined" : "p-button-rounded p-button-danger p-button-outlined"}
                                                        onClick={() => key.counter === 0 ? addToCalendar() : removeFromCalendar(key.counter)}
                                                        id={"form-input-calendar-button-" + key.counter}
                                                        disabled={viewPage}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                            
                            <div className="flex flex-column gap-2">
                                <span for="form-file-preview">Banner início (Preview) - max. 1mb</span>
                                <FileUpload ref={previewBannerRef} mode="basic" chooseLabel="Escolher" accept="image/*" customUpload id="form-file-preview" auto
                                    uploadHandler={(e)=>{
                                        previewBannerUpload(e);
                                    }}
                                    disabled={viewPage}
                                />
                            </div>

                            <div className="flex flex-column gap-2">
                                <span for="form-file-principal">Banner Detalhe (principal)</span>
                                <FileUpload ref={principalBannerRef} mode="basic" chooseLabel="Escolher" accept="image/*" customUpload id="form-file-principal" auto
                                    uploadHandler={(e)=>{
                                        principalBannerUpload(e);
                                    }}
                                    disabled={viewPage}
                                />
                            </div>

                            <div className="flex flex-row gap-2 align-items-center">
                                <Checkbox checked={sendToEveryone} onChange={e => setSendToEveryone(e.checked)} id="form-checkbox-send-to-everyone" disabled={viewPage}/>
                                <span className="text-xl" for="form-checkbox-send-to-everyone">Enviar push para todos</span>
                            </div>

                            <div className="flex flex-row gap-2 align-items-center">
                                <Checkbox checked={!sendToEveryone} onChange={e => setSendToEveryone(!e.checked)} id="form-checkbox-custom-filters" disabled={viewPage}/>
                                <span className="text-xl" for="form-checkbox-custom-filters">Aplicar filtros personalizados</span>
                            </div>

                            {
                                !sendToEveryone &&
                                <>
                                    <div className="flex flex-row gap-2 align-items-center">
                                        <Checkbox checked={sendToAbsent} onChange={e => setSendToAbsent(e.checked)} id="form-checkbox-send-to-absent" disabled={viewPage}/>
                                        <span className="text-xl" for="form-checkbox-send-to-absent">Enviar para cartões sem movimentação</span>
                                    </div>

                                    <div className="grid gap-2">
                                        <div className="col gap-2">
                                            <span for="form-input-number-min-age">Idade mínima</span>
                                            <InputNumber value={minimumAge} onValueChange={(e) => setMinimumAge(e.value)} suffix=" anos" id="form-input-number-min-age" disabled={viewPage}/>
                                        </div>
                                        <div className="col gap-2">
                                            <span for="form-input-number-max-age">Idade máxima</span>
                                            <InputNumber value={maximumAge} onValueChange={(e) => setMaximumAge(e.value)} suffix=" anos" id="form-input-number-max-age" disabled={viewPage}/>
                                        </div>
                                    </div>

                                    <div className="flex flex-column gap-2">
                                        <span for="form-dropdown-category">Categorias</span>
                                        <MultiSelect 
                                            value={categorias} 
                                            options={categoriasList} 
                                            onChange={(e)=>setCategorias(e.target.value)} 
                                            optionLabel="label" 
                                            placeholder="Selecione as Categorias" 
                                            display="chip" 
                                            id="form-dropdown-category"
                                            disabled={viewPage}
                                        />
                                    </div>

                                    <div className="grid gap-2 align-items-center">
                                        <div className="col flex align-items-center">
                                            <InputSwitch checked={searchMargin} onChange={(e) => setSearchMargin(e.value)} id="form-switch-search-margin" disabled={viewPage}/>
                                        </div>
                                        <span className="text-xl col-10" for="form-switch-search-margin">Consultar margem na processadora para os filtros aplicados</span>
                                    </div>

                                    {
                                        searchMargin &&
                                        <>
                                            <div className="grid gap-2">
                                                <div className="col">
                                                    <span for="form-input-number-min-age">Margem mínima</span>
                                                    <InputNumber value={minimumMargin} onValueChange={(e) => setMinimumMargin(e.value)} mode="currency" currency="BRL" locale="pt-BR" disabled={viewPage} />
                                                </div>
                                                <div className="col">
                                                    <span for="form-input-number-min-age">Margem máxima</span>
                                                    <InputNumber value={maximumMargin} onValueChange={(e) => setMaximumMargin(e.value)} mode="currency" currency="BRL" locale="pt-BR" disabled={viewPage} />
                                                </div>
                                            </div>
                                            <Toolbar
                                                left={'Ao clicar no botão "Realizar Consulta" haverá cobrança por consulta de usuário'}
                                                style={{background: "#FFE877"}}
                                            />
                                            <ConfirmDialog visible={visibleDialog} onHide={() => setVisibleDialog(false)} message="Tem certeza que deseja consultar margem?"
                                                accept={handleSearchMargin} reject={()=>{}} acceptLabel="Consultar" rejectLabel="Não" />
                                            <Button onClick={() => setVisibleDialog(true)} label="Realizar Consulta" className="ui-button-warning" disabled={viewPage}/>
                                        </>
                                    }
                                </>
                            }

                        </div>
                    </div>
                    <div className="flex flex-row p-2 justify-content-center gap-5">
                        <div>
                            <Button label="Salvar" onClick={saveNotification} disabled={viewPage || blockSaveButton}/>
                        </div>
                        <div>
                            <Button label="Enviar" onClick={sendNotification} className="p-button-success" disabled={viewPage || blockSaveButton}/>
                        </div>
                        <div>
                            <Button label="Voltar" onClick={()=>history.goBack()} className="p-button-outlined p-button-help"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-column" style={{width: "46%"}}>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full p-2 gap-5 justify-content-around">
                            <div className="flex col-10">
                                <Button
                                    className="card mb-0 shadow-lg flex justify-content-center flex-column gap-2"
                                    style={{
                                        borderRadius: "1rem",
                                        borderWidth: 0,
                                        background: '#F4F4F4',
                                        cursor: 'default',
                                    }}
                                >
                                    <span className="text-xl font-bold" style={{color: '#000'}}>Alcance:</span>
                                    <span className="text-md" style={{color: '#000'}}>Todos os usuários</span>
                                    <div className="valueCard text-100 font-medium text-xl">
                                        {alcance}
                                    </div>
                                    <span className="text-md font-bold" style={{color: '#00000075'}}>Usuários que serão notificados</span>
                                </Button>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-2 gap-5 justify-content-around">
                            <div className="flex col-10">
                                <Button
                                    className="card mb-0 shadow-lg flex justify-content-center flex-column gap-2"
                                    style={{
                                        borderRadius: "1rem",
                                        borderWidth: 0,
                                        background: '#F4F4F4',
                                        cursor: 'default'
                                    }}
                                >
                                    <span className="text-xl font-bold" style={{color: '#000'}}>Alcance:</span>
                                    <span className="text-md" style={{color: '#000'}}>Usuários que possuem margem</span>
                                    <div className="valueCard text-100 font-medium text-xl">
                                        {alcanceMargem ? alcanceMargem.length : "--"}
                                    </div>
                                    <span className="text-md font-bold" style={{color: '#00000075'}}>Usuários que serão notificados</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full p-2 gap-5">
                            <div className="flex flex-column justify-content-center col-12 gap-2">
                                <span className="text-center text-xl" style={{color: '#000'}}>Preview</span>
                                <div className="card" style={{height: 550, background: '#6366F1'}}>
                                    <div className="card w-full h-full" style={{background: '#414141'}}>
                                        <div className="card flex flex-row align-items-center justify-content-between gap-2 px-3" style={{height: 75}}>
                                            <img src="../../../assets/layout/images/logo.png" width={35}></img>
                                            <span className="text-xs" style={{wordWrap: 'break-word', overflowWrap: 'break-word', width: '20ch'}}>{message}</span>
                                            {
                                                previewImage &&
                                                <img style={{
                                                    borderRadius: "0.2rem",
                                                }} src={previewImage} width={40} height={40}></img>
                                            }
                                        </div>
                                        <div className="card flex flex-column align-items-center gap-3 px-3" style={{height:375}}>
                                            {
                                                previewImage &&
                                                <img style={{
                                                    borderRadius: "1rem",
                                                }} src={previewImage} width={200} height={200}></img>
                                            }
                                            <span className="font-bold text-lg text-center" style={{wordWrap: 'break-word', overflowWrap: 'break-word', width: '18ch'}}>{titulo}</span>
                                            <span className="text-center" style={{wordWrap: 'break-word', overflowWrap: 'break-word', width: '20ch'}}>{message}</span>
                                            <div className="card w-full flex justify-content-center p-3" style={{background: '#D9D9D9'}}>Visualizar</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-2 gap-5">
                            <div className="flex flex-column justify-content-center col-12 gap-2">
                                <span className="text-center text-xl" style={{color: '#000'}}>Notificação</span>
                                <div className="card" style={{height: 550, background: '#6366F1'}}>
                                    <div className="card flex flex-column align-items-center gap-3 p-0" style={{height: '100%', background: '#414141'}}>
                                        {
                                            principalImage &&
                                            <img style={{
                                                borderRadius: "1rem",
                                                width: '100%',
                                                height: '70%'
                                            }} src={principalImage}></img>
                                        }
                                        <span className="font-bold text-lg text-white text-center" style={{wordWrap: 'break-word', overflowWrap: 'break-word', width: '20ch'}}>{titulo}</span>
                                        <span className="text-center text-white" style={{wordWrap: 'break-word', overflowWrap: 'break-word', width: '20ch'}}>{message}</span>
                                        <div className="card flex justify-content-center p-3 text-black" style={{background: '#D9D9D9', width: '80%'}}>Contratar Agora</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoadingSpinner visualiza={loadingSpinner} />
        </>
    )
}

export default Formulario;