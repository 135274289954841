import React, { useState } from "react";

import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./App.scss";
import Login from "./pages/Login/Login";
import LoginReset from "./pages/Login/LoginReset";
import AppMain from "./AppMain";
import AppMainPrisma from "./AppMainPrisma";
import AppMainFacaJus from "./AppMainFacaJus";
import AppMainFacaSeguros from "./AppMainFacaSeguros";

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AppChoosePlatform from "./AppChoosePlatform";
import AppNotification from "./AppNotification";


const App = () => {
  const [token, setToken] = useState(null);
  const [logado, setLogado] = useState(sessionStorage.getItem('logado'));
  const [platform, setPlatform] = useState(sessionStorage.getItem('platform'));

  const AutenticaLogin = (token) => {
    setToken(token);
    setLogado(sessionStorage.getItem('logado'));
    
    return true;
  }

  const SelectPlatform = (platform) => {
    sessionStorage.setItem("platform", platform);
    setPlatform(sessionStorage.getItem('platform'));
  }

  if (platform) {

    switch (platform) {
      case 'adm': // Portal Administrativo

        if (sessionStorage.getItem('empresa_id') == 1) return <AppMain></AppMain>;
        if (sessionStorage.getItem('empresa_id') == 2) return <AppMainPrisma></AppMainPrisma>;
        if (sessionStorage.getItem('empresa_id') == 3) return <AppMainFacaJus></AppMainFacaJus>;
        if (sessionStorage.getItem('empresa_id') == 4) return <AppMainFacaSeguros></AppMainFacaSeguros>;

      case 'ntf': // Portal de Notificação
        return <AppNotification/>;

      default:
        break;
    }

  }
  
  if (logado) {

    let needsToSelectPlatform = false
    if(sessionStorage.getItem('acessos').includes('ntf/envio/acessar') || sessionStorage.getItem('acessos').includes('ntf/analise')){
      needsToSelectPlatform = true
    } else {
      setPlatform('adm');
      return true;
    }

    if(needsToSelectPlatform) return <AppChoosePlatform SelectPlatform={SelectPlatform}></AppChoosePlatform>; // Acesso à duas plataformas
    
  }

  return (

    <Router>
      <Switch>
        <Route path="/Login">
          <Login setToken={AutenticaLogin}></Login>
        </Route>
        <Route path="/LoginReset/:nome/:id/:tokenReset" component={LoginReset} />
        <Redirect to="/Login" />;
      </Switch>
    </Router >

  );
};

export default App;

