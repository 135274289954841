import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory, Link } from "react-router-dom";
import { Button } from "primereact/button";

import { CartaoVirtualService } from "../../service/Servidor/CartaoVirtualService";
import { EsteiraService } from "../../service/EsteiraService";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const _Motivo = () => {

  const toast = useRef(null);
  const history = useHistory();
  const { servidor, id, status } = useParams();

  const cartaoVirtual = new CartaoVirtualService();
  const esteira = new EsteiraService();

  const _header = "Administrativo - Operacional - Esteira - Solicitações - Motivo";
  const link_voltar = "/esteiraServidor_Index/" + servidor;


  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [createMotivo, setCreateMotivo] = useState(false);
  const [selectedMotivo, setSelectedMotivo] = useState({});
  const [motivo, setMotivo] = useState("");
  const [motivoList, setMotivoList] = useState([]);

  const [dadosServidor, setDadosServidor] = useState({});

  useEffect(()=>{
    setLoadingSpinner(true)
    cartaoVirtual.getById(servidor)
    .then(
      (data) => 
        data.status === true
          ? setDadosServidor(data.dados[0])
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");        
    });

    esteira.listMotivos()
    .then(
      (data) => 
        data.status === true
          ? setMotivoList(data.dados)
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");    
    });
  }, []);

  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  useEffect(() => {
    if (retornoDado.status === false) {     
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    } else if(retornoDado.status === true){
      toast.current.show({
        severity: "success",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  function handleChangeStatus(){
    setLoadingSpinner(true);
    const usedMotivo = createMotivo ? motivo : selectedMotivo.name;

    esteira.actionAprovacao(id, status, usedMotivo)
    .then(
      (data) => 
        data.status === true
          ? setRetornoDado(data.dados)
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");    
    });
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={()=>{
            return (
              <div>
                <p className="text-xl">{_header}</p>
                <div className="flex gap-5 my-5">
                  <Button
                    className="p-button p-button-success"
                    label="Adicionar a solicitação"
                    onClick={()=>handleChangeStatus()}
                  />
                  <Link to={link_voltar}>
                    <Button
                      label="Voltar"
                    />
                  </Link>
                </div>
                <p className="text-2xl">{dadosServidor.cav_nome}</p>
                <div className="mt-2">
                  <p className="text-2xl font-bold">Motivo</p>
                  <p className="text-md">Selecione um motivo existente ou crie um novo motivo.</p>
                  <div className="mt-5">
                    <p className="text-xl font-bold">Selecionar motivo</p>
                    <Dropdown 
                      value={selectedMotivo} 
                      onChange={(e) => {
                        setSelectedMotivo(e.value)
                        setCreateMotivo(false)
                      }} 
                      options={motivoList} 
                      optionLabel="name" 
                      placeholder="Selecionar motivo" 
                      className="w-full"
                    />
                  </div>
                  <div className="mt-5">
                    <p className="text-xl font-bold">Criar novo motivo</p>
                    <InputText 
                      value={motivo} 
                      onChange={(e) => {
                        setMotivo(e.target.value)
                        setCreateMotivo(true)
                      }}  
                      className="w-full"
                      placeholder="Descreva motivo em 100 caracteres."
                    />
                  </div>
                </div>
              </div>
            )
          }}></Toolbar>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Motivo, comparisonFn);
