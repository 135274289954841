import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory, Link } from "react-router-dom";
import { Button } from "primereact/button";

import { CartaoVirtualService } from "../../service/Servidor/CartaoVirtualService";
import { EsteiraService } from "../../service/EsteiraService";
import { TabPanel, TabView } from "primereact/tabview";

const _Index = () => {

  const toast = useRef(null);
  const dt = useRef(null);
  const history = useHistory();

  const cartaoVirtual = new CartaoVirtualService();
  const esteira = new EsteiraService();

  const _header = "Administrativo - Operacional - Esteira - Solicitações";
  const link_voltar = "/esteira";

  const { id } = useParams();

  const [loadingSpinner, setLoadingSpinner] = useState(false);
  
  const [atualizarTabela, setAtualizarTabela] = useState(true);

  const [servidor, setServidor] = useState({});
  const [solicitacoes, setSolicitacoes] = useState({
    saque: [],
    compras: [],
    antecipacao: []
  });

  useEffect(()=>{
    setLoadingSpinner(true)
    cartaoVirtual.getById(id)
    .then(
      (data) => 
        data.status === true
          ? setServidor(data.dados[0])
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");        
    });

    setAtualizarTabela(true);
  }, []);

  useEffect(()=>{
    if(atualizarTabela){
      setLoadingSpinner(true)
      esteira.listarOperacoesServidor(id)
      .then(
        (data) =>
          data.status === true
            ? handleSolicitacaoData(data.dados)
            : setRetornoDado(data),
            setLoadingSpinner(false)
      )
      .catch((error) => {              
        console.log(error)
        history.push("/semToken");        
      });

      setAtualizarTabela(false);
    }
  }, [atualizarTabela])

  function handleSolicitacaoData(solicitacoes){
    let solicitacoesTratadas = {
      saque: [],
      compras: [],
      antecipacao: []
    }

    solicitacoes.forEach(solicitacao => {
      switch (solicitacao.tipo) {
        case 1:
          solicitacoesTratadas.saque.push(solicitacao)
          break;
        case 2:
          solicitacoesTratadas.compras.push(solicitacao)
          break;
        case 3:
          solicitacoesTratadas.antecipacao.push(solicitacao)
          break;
      
        default:
          break;
      }
    });

    setSolicitacoes(solicitacoesTratadas)
  }

  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  useEffect(() => {
    if (retornoDado.status === false) {     
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    } else if(retornoDado.status === true){
      toast.current.show({
        severity: "success",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);


  function downloadFile(code, fileName) {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${code}`;
    link.download = fileName;
  
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  }

  const pageHeader = () => {
    return (
      <div>
        <p className="text-xl">{_header}</p>
        <div className="my-5">
          <Link to={link_voltar}>
            <Button
              label="Voltar"
            />
          </Link>
        </div>
        <p className="text-2xl">{servidor.cav_nome}</p>
      </div>
    );
  };

  const header = (
    <>
      <p className="text-3xl">Solicitações - Aprovação</p>
    </>
  );

  const codeBodyTemplate = (data) => {
    const codigo = data.code.toString().padStart(3, '0')

    return (
      <p>{codigo}</p>
    )
  }

  const dataBodyTemplate = (data) => {
    return (
      <p>{data.data}</p>
    )
  }

  const statusBodyTemplate = (data) => {
    return (
      <p>{data.status}</p>
    )
  }

  const motivoBodyTemplate = (data) => {
    return (
      <p>{data.motivo}</p>
    )
  }

  const arquivoBodyTemplate = (data) => {
    return (
      <Button
        tooltip={"Arquivo"}
        icon={"pi pi-download"}
        className="p-button-rounded p-button-warning"
        onClick={()=>downloadFile(data.arquivo, "solicitacao-de-aprovacao-" + data.code + ".pdf")}
      />
    )
  }

  const actionBodyTemplate = (data) => {
    const actionDisabled = data.status_id === 4 || data.status_id === 3 ? true : false

    return (
      <div className="flex gap-2">
        <Button
          className="p-button p-button-success"
          label="Aprovar"
          onClick={()=>handleChangeStatus(data.code, 4)}
          disabled={actionDisabled}
        />
        {
          actionDisabled ?
            <>
              <Button
                className="p-button p-button-warning"
                label="Pendenciar"
                disabled={actionDisabled}
              />
              <Button
                className="p-button p-button-danger"
                label="Reprovar"
                disabled={actionDisabled}
              />
            </>
          :  
            <>
              <Link to={`../esteiraServidor_Motivo/${id}/${data.code}/2`}>
                <Button
                  className="p-button p-button-warning"
                  label="Pendenciar"
                />
              </Link>
              <Link to={`../esteiraServidor_Motivo/${id}/${data.code}/3`}>
                <Button
                  className="p-button p-button-danger"
                  label="Reprovar"
                />
              </Link>
            </>
          
        }
         <Link to={`../esteiraServidor_Historico/${id}/${data.code}`}>
          <Button
            className="p-button"
            label="Histórico"
          />
        </Link>
        {
          data.status_id === 4 ?
            <Link to={`../relEmprestimo/${id}`}>
              <Button
                className="p-button"
                label="Proposta"
              />
            </Link>
          : 
            <Button
              className="p-button"
              label="Proposta"
              disabled={true}
            />
        }
      </div>
    )
  }

  function handleChangeStatus(id, status){
    esteira.actionAprovacao(id, status)
    .then(
      (data) => 
        data.status === true
          ? setRetornoDado(data.dados)
          : setRetornoDado(data),
          setLoadingSpinner(false)
    )
    .catch((error) => {              
      console.log(error)
      history.push("/semToken");    
    });

    setAtualizarTabela(true);

  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={pageHeader}></Toolbar>
          <TabView>
            <TabPanel header="Saque Fácil">
              <DataTable
                ref={dt}
                value={solicitacoes.saque}
                dataKey="id"
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
                emptyMessage="Não há dados."
                header={header}
                responsiveLayout="scroll"
              >
                <Column
                  field="code"
                  header="Cód."
                  body={codeBodyTemplate}
                  headerStyle={{ width: "4%", minWidth: "2rem" }}
                  sortable
                  ></Column>
                <Column
                  field="data"
                  header="Data"
                  body={dataBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column             
                  field="motivo"
                  header="Motivo"
                  body={motivoBodyTemplate}
                  headerStyle={{ width: "20%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="arquivo"
                  header="Arquivo"
                  body={arquivoBodyTemplate}
                  align={'center'}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                ></Column>
                <Column       
                  align={"center"}
                  field="actions"
                  header="Ações"
                  body={actionBodyTemplate}
                  headerStyle={{ width: "40%", minWidth: "10rem" }}
                ></Column>
              </DataTable>
            </TabPanel>
            <TabPanel header="Cartão compras">
              <DataTable
                ref={dt}
                value={solicitacoes.compras}
                dataKey="id"
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
                emptyMessage="Não há dados."
                header={header}
                responsiveLayout="scroll"
              >
                <Column
                  field="code"
                  header="Cód."
                  body={codeBodyTemplate}
                  headerStyle={{ width: "4%", minWidth: "2rem" }}
                  sortable
                  ></Column>
                <Column
                  field="data"
                  header="Data"
                  body={dataBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column             
                  field="motivo"
                  header="Motivo"
                  body={motivoBodyTemplate}
                  headerStyle={{ width: "20%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="arquivo"
                  header="Arquivo"
                  body={arquivoBodyTemplate}
                  align={'center'}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                ></Column>
                <Column       
                  align={"center"}
                  field="actions"
                  header="Ações"
                  body={actionBodyTemplate}
                  headerStyle={{ width: "40%", minWidth: "10rem" }}
                ></Column>
              </DataTable>
            </TabPanel>
            <TabPanel header="Cartão antecipação">
              <DataTable
                ref={dt}
                value={solicitacoes.antecipacao}
                dataKey="id"
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
                emptyMessage="Não há dados."
                header={header}
                responsiveLayout="scroll"
              >
                <Column
                  field="code"
                  header="Cód."
                  body={codeBodyTemplate}
                  headerStyle={{ width: "4%", minWidth: "2rem" }}
                  sortable
                  ></Column>
                <Column
                  field="data"
                  header="Data"
                  body={dataBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column             
                  field="motivo"
                  header="Motivo"
                  body={motivoBodyTemplate}
                  headerStyle={{ width: "20%", minWidth: "10rem" }}
                  sortable
                  ></Column>
                <Column
                  field="arquivo"
                  header="Arquivo"
                  body={arquivoBodyTemplate}
                  align={'center'}
                  headerStyle={{ width: "12%", minWidth: "10rem" }}
                ></Column>
                <Column       
                  align={"center"}
                  field="actions"
                  header="Ações"
                  body={actionBodyTemplate}
                  headerStyle={{ width: "40%", minWidth: "10rem" }}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
