export class Funcoes {
  /**
   * Pesquisa se possui acesso a determinado item do sistema conforme seu perfil
   * @param {*} permissao
   * @returns
   */
  permissaoAcesso(permissao) {
    const permissoes = sessionStorage.getItem("acessos");
    const acessos = permissoes.trim().split(",");
    const existe = acessos.find((item) => item === permissao);
    return existe === undefined ? false : true;
  }

  /**
   * Gera lista do ano e mês desde 2020
   * @returns
   */
  geraArrayAnoMes() {
    const years = [];
    const dados = [];
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    const meses = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];

    for (let year = 2020; year <= ano; year++) {
      years.push(year);
    }

    const dates = years.map((year) => {
      return months.map((month) => {
        dados.push({
          id: year + month,
          descricao: meses[month - 1] + "/" + year,
        });
      });
    });
    return dados;
  }

  /**
   * Lista os anos anteriores ao atual
   * @returns 
   */
  geraArrayAno() {
    const dados = [];    
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();      

    for (let year = ano; year >= 2020; year--) {
      dados.push({id:year,ano:year});
    }

    return dados;
  }


  geraArrayMes() {
    const dados = [];
    dados.push({id: 0 ,mes:"Todos"});
    dados.push({id: 1 ,mes:"Jan"});
    dados.push({id: 2 ,mes:"Fev"});
    dados.push({id: 3 ,mes:"Mar"});
    dados.push({id: 4 ,mes:"Abr"});
    dados.push({id: 5 ,mes:"Mai"});
    dados.push({id: 6 ,mes:"Jun"});
    dados.push({id: 7 ,mes:"Jul"});
    dados.push({id: 8 ,mes:"Ago"});
    dados.push({id: 9 ,mes:"Set"});
    dados.push({id: 10 ,mes:"Out"});
    dados.push({id: 11 ,mes:"Nov"});
    dados.push({id: 12 ,mes:"Dez"});
      
    return dados;
  }

  geraArrayApenasMeses() {
    const dados = [];
    dados.push({id: 1 ,mes:"Jan"});
    dados.push({id: 2 ,mes:"Fev"});
    dados.push({id: 3 ,mes:"Mar"});
    dados.push({id: 4 ,mes:"Abr"});
    dados.push({id: 5 ,mes:"Mai"});
    dados.push({id: 6 ,mes:"Jun"});
    dados.push({id: 7 ,mes:"Jul"});
    dados.push({id: 8 ,mes:"Ago"});
    dados.push({id: 9 ,mes:"Set"});
    dados.push({id: 10 ,mes:"Out"});
    dados.push({id: 11 ,mes:"Nov"});
    dados.push({id: 12 ,mes:"Dez"});
      
    return dados;
  }

  /**
   * Formata no padrão americano "DD/MM/YYYY" para "YYYY-MM-DD"
   * @param {*} data
   * @returns
   */
  formataDataPadraoAmericano(data) {
    if (data === null || data === "") {
      return null;
    }
    const dia = data.toString().slice(0, 2);
    const mes = data.toString().slice(3, 5);
    const ano = data.toString().slice(6, 10);
    const f = ano + "-" + mes + "-" + dia;
    return f;
  }

  /**
   * Formata no padrão "DD/MM/YYYY" para "YYYYMMDD"
   * @param {*} data
   * @returns
   */
  formataDataParaYYYYMMDD(data) {
    if (data === null || data === "") {
      return null;
    }
    const dia = data.toString().slice(0, 2);
    const mes = data.toString().slice(3, 5);
    const ano = data.toString().slice(6, 10);
    const f = ano + mes + dia;
    return f;
  }


  /**
   * Formata no padrão "YYYY-MM-DD" para  "DD/MM/YYYY" 
   * @param {*} data
   * @returns
   */
  formataDataParaDDMMYYYY(data) {
    if (data === null || data === "") {
      return null;
    }
    const dia = data.toString().slice(8, 10);;
    const mes = data.toString().slice(5, 7);
    const ano = data.toString().slice(0, 4);
    const f = dia + "/" + mes + "/" + ano;
    return f;
  }

  /**
   * Formata no padrão "MM" 
   * @param {*} data
   * @returns
   */
  formataDataParaMM(data) {
    if (data === null || data === "") {
      return null;
    }
    
    const mes = data.toString().slice(5, 7);    
    
    return mes;
  }

   /**
   * Formata no padrão "YYYYY" 
   * @param {*} data
   * @returns
   */
   formataDataParaYYYY(data) {
    if (data === null || data === "") {
      return null;
    }
    
    const ano = data.toString().slice(0, 4);    
    
    return ano;
  }

  /**
   * Transforma o valor pt-Br para o en-US
   * @param {*} valor  // 1.000,90 para 1000.90
   * @returns
   */
  formataValorParaUS(valor) {
    const valorSemPonto = valor.replace(".", "");
    const valorFinal = valorSemPonto.replace(",", ".");
    return valorFinal;
  }

  /**
   * Transforma o valor en-US para o pt-Br
   * @param {*} valor  // 1,000.90 para 1000,90
   * @returns
   */
  formataValorParaBR(valor) {
    const ptBRNumberFormat = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
    return ptBRNumberFormat.format(valor);
  }

  /**
   * Preenche com zeros a frente conforme o número de digitos que deve ter
   * Exemplo: 345 e tamanho 5 então será 00345
   * @param {*} string
   * @param {*} length
   * @returns
   */
  replaceWithZero(string, length) {
    if (string.length >= length) {
      return string;
    }

    return "0".repeat(length - string.length) + string;
  }

  retornaAnoMes() {
    const data = new Date(Date.now());
    const mes =
      parseInt(
        data.getMonth()
      ) + 1;
    const anoMes = data.getFullYear() + "" + ('00'+mes).slice(-2);
    return anoMes;
  }

  retornaAno() {
    const data = new Date(Date.now());    
    return  data.getFullYear();
  }    

  retornaMes() {
    const data = new Date(Date.now());    
    return  parseInt(data.getMonth()) + 1;
  }

  /**
   * Remove a máscara dos inputs de data, e formata de modelo BR para Americano
   * Exemplo: De: 25/12/2000 -> 2000/12/25
   * @param {*} data
   * @returns
   */
  handleDate(data) {

    const newDate = data.replace(/[^0-9]/g, '');

    const day = newDate.slice(0, 2);
    const month = newDate.slice(2, 4);
    const year = newDate.slice(-4);

    if (!newDate) return false;

    return year + "-" + month + "-" + day;

  }

  /**
   * Recebe data em modelo Americano e verifica se é válida
   * @param {*} date
   * @returns
   */
  isValidDate(date) {
    return !isNaN(new Date(date));
  }

  /**
   * Recebe hora em formato de banco de dados, retorna apenas hora:minuto 
   * Ex: 16:30:25.222 -> 16:30
   * @param {string} hora
   * @returns
   */
  formataHoraParaHHII(hora) {
    return hora.toString().slice(0, 5); 
  }

  /**
   * Recebe um horário e verifica se é válido
   * @param {string} horario
   * @returns
   */
  isHorarioValido(horario) {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;

    return regex.test(horario);
  }
}
