import api from "./Api";

export class EsteiraService {
  async listarOperacoesEsteira() {
    return await api.
      get("/administrativo/esteira/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async listarOperacoesServidor(id) {
    return await api.
      post("administrativo/esteira/servidor", {
        id:id
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async actionAprovacao(id, status, motivo = null) {
    return await api.
      post("administrativo/esteira/aprovacao", {
        id: id,
        status: status,
        motivo: motivo
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async listMotivos() {
    return await api.
      get("administrativo/esteira/motivos", 
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

  async historico(id) {
    return await api.
      post("administrativo/esteira/historico", {
        id:id
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) =>  res.data);
  }

}

