import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams, Link, useHistory } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Dropdown } from "primereact/dropdown";

import { PerfilService } from "../../service/PerfilService";
import { UsuariosService } from "../../service/UsuariosService";

import Acessos from "../../components/Acessos/Acessos";

const _Form = () => {
  const history = useHistory();
  const isset = (ref) => typeof ref !== "undefined";
  const toast = useRef();
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const [campoHabilitado, SetCampoHabilitado] = useState(false);
  const [tipoAcesso, setTipoAcesso] = useState(false);
  const [acessosPerfil, setAcessosPerfil] = useState(0);

  let { id } = useParams();
  let { tipo } = useParams();
  const link_voltar = "/perfil";
  const cabecalho_form = "Perfil";

  let header =
    tipo === "I" ? (
      <h5>{cabecalho_form} - Cadastrar</h5>
    ) : tipo === "U" ? (
      <h5>{cabecalho_form} - Alterar</h5>
    ) : (
      <h5>{cabecalho_form} - Visualizar</h5>
    );

  let emptyDados = {
    per_id: null,
    per_nome: "",
    per_descricao: "",
    per_acessos: " ",
    per_ust_id: "",
  };

  let retornoDados = {
    status: null,
    mensagem: "",
  };

  const [modelDado, setModelDado] = useState(emptyDados);
  const [retornoDado, setRetornoDado] = useState(retornoDados);
  const perfilService = new PerfilService();

  let lista_expanded = [
    "adm",
    "adm/agenda",
    "adm/feriados",
    "adm/cartao",
    "adm/convenio",
    "adm/estabelecimento",
    "adm/processadora",
    "adm/perfil",
    "adm/servidor",
    "adm/tipos",
    "adm/tipos/plano",
    "adm/tipos/estabelecimento",
    "adm/usuarios",
    "corr",
    "corr/servidor",
    "corr/relatorio",
    "corr/simular",
    "corr/esteira/acessar",
    "ger",
    "ger/repasse",
    "ger/relatorio",
    "ger/relatorio/aplicativo",
    "ger/relatorio/compra",
    "ger/relatorio/assinaturas",
    "ger/relatorio/cartao",
    "ger/relatorio/cartaolancamentos",
    "ger/relatorio/cartaosolicitadoscancelados",
    "ger/relatorio/emprestimo",
    "ger/seguros",
    "ger/seguros/SulAmerica",
    'ger/relatorio/comprasFacajus',
    'ger/relatorio/liberarmargem',
    "ntf",
    "ntf/envio",
    "ntf/envio/acessar",
    "ntf/envio/push/alterar",
    "ntf/envio/push/visualizar",
    "ntf/envio/push/criar",
    "ntf/envio/push/enviar",
    "ntf/envio/push/consultar/margem",
    "ntf/analise",
  ];

  let lista_expanded_PRISMA = [
    "adm",
    "adm/perfil",
    "adm/usuarios",
    "adm/servidor",
    "corr",
    "corr/servidor",
    "corr/relatorio",
    "corr/simular",
    "ger",
    "ger/relatorio",
    "ger/relatorio/aplicativo",
    "ger/relatorio/compra",
    "ger/relatorio/cartao",
    "ger/relatorio/emprestimo",
  ];

    
  let lista_expanded_FACAJUS = ["adm", "adm/perfil", "ger/relatorio/assinaturas", "adm/usuarios"];

  let lista_expanded_FACASEGUROS = [
    "adm",
    "adm/perfil",
    "adm/usuarios",
    "corr",
    "corr/simular",
  ];

  const lista_nodes = [
    {
      value: "adm",
      label: "ADMINISTRATIVO",
      children: [
        {
          value: "adm/agenda",
          label: "AGENDA DE CORTE",
          children: [
            {
              value: "adm/agenda/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/agenda/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/agenda/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/agenda/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/feriados",
          label: "AGENDA DE CORTE FERIADOS",
          children: [
            {
              value: "adm/feriados/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/feriados/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/feriados/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/feriados/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },

        {
          value: "adm/convenio",
          label: "CONVÊNIOS",
          children: [
            {
              value: "adm/convenio/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/convenio/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/convenio/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/convenio/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },

        {
          value: "adm/estabelecimento",
          label: "ESTABELECIMENTOS",
          children: [
            {
              value: "adm/estabelecimento/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/estabelecimento/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/estabelecimento/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/estabelecimento/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/perfil",
          label: "PERFIL",
          children: [
            {
              value: "adm/perfil/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/perfil/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/perfil/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/perfil/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },

        {
          value: "adm/processadora",
          label: "PROCESSADORAS",
          children: [
            {
              value: "adm/processadora/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/processadora/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/processadora/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/processadora/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },

        {
          value: "adm/servidor",
          label: "SERVIDOR",
          children: [
            {
              value: "adm/servidor/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/servidor/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/servidor/averbarcartao",
              label: "AVERBAR CARTÃO",
              children: [],
            },
            {
              value: "adm/servidor/averbaremprestimo",
              label: "AVERBAR SAQUE",
              children: [],
            },
            {
              value: "adm/servidor/app",
              label: "BLOQUEAR/DESBLOQUEAR - APP",
              children: [],
            },
            {
              value: "adm/servidor/volus",
              label: "BLOQUEAR/DESBLOQUEAR CARTÃO Benefícios",
              children: [],
            },
            {
              value: "adm/servidor/cancelaraverbacao",
              label: "CANCELAR AVERBAÇÃO",
              children: [],
            },
            {
              value: "adm/servidor/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/servidor/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
            {
              value: "adm/servidor/quitar",
              label: "QUITAR PARCELAS",
              children: [],
            },
            {
              value: "adm/servidor/seguros",
              label: "SEGUROS",
              children: [],
            },
          ],
        },

        {
          value: "adm/tipos",
          label: "TIPOS",
          children: [
            {
              value: "adm/tipos/estabelecimento",
              label: "ESTABELECIMENTO",
              children: [
                {
                  value: "adm/tipos/estabelecimento/acessar",
                  label: "ACESSAR",
                  children: [],
                },
                {
                  value: "adm/tipos/estabelecimento/alterar",
                  label: "ALTERAR",
                  children: [],
                },
                {
                  value: "adm/tipos/estabelecimento/incluir",
                  label: "INCLUIR",
                  children: [],
                },
                {
                  value: "adm/tipos/estabelecimento/visualizar",
                  label: "VISUALIZAR",
                  children: [],
                },
              ],
            },
            {
              value: "adm/tipos/plano",
              label: "PLANO",
              children: [
                {
                  value: "adm/tipos/plano/acessar",
                  label: "ACESSAR",
                  children: [],
                },
                {
                  value: "adm/tipos/plano/alterar",
                  label: "ALTERAR",
                  children: [],
                },
                {
                  value: "adm/tipos/plano/incluir",
                  label: "INCLUIR",
                  children: [],
                },
                {
                  value: "adm/tipos/plano/visualizar",
                  label: "VISUALIZAR",
                  children: [],
                },
              ],
            },
          ],
        },

        {
          value: "adm/usuarios",
          label: "USUÁRIOS",
          children: [
            {
              value: "adm/usuarios/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/usuarios/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/usuarios/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/usuarios/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
      ],
    },
    {
      value: "corr",
      label: "CORRESPONDENTE BANCÁRIO",
      children: [
        {
          value: "corr/servidor",
          label: "SERVIDOR",
          children: [
            {
              value: "corr/servidor/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "corr/servidor/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "corr/servidor/averbarcartao",
              label: "AVERBAR CARTÃO",
              children: [],
            },
            {
              value: "corr/servidor/averbaremprestimo",
              label: "AVERBAR SAQUE",
              children: [],
            },
            {
              value: "corr/servidor/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "corr/servidor/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
            {
              value: "corr/servidor/seguros",
              label: "SEGUROS",
              children: [],
            },
          ],
        },
        {
          value: "corr/relatorio",
          label: "RELATÓRIOS",
          children: [
            {
              value: "corr/relatorio/emprestimos",
              label: "EMPRÉSTIMOS",
              children: [
                {
                  value: "corr/relatorio/emprestimos/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          value: "corr/simular",
          label: "SIMULAR SAQUE",
          children: [
            {
              value: "corr/simular/acessar",
              label: "ACESSAR",
              children: [],
            },
          ],
        },
        {
          value: "corr/esteira",
          label: "ESTEIRA",
          children: [
            {
              value: "corr/esteira/acessar",
              label: "ACESSAR",
              children: [],
            },
          ],
        },
      ],
    },
    {
      value: "ger",
      label: "GERENCIAL",
      children: [
        {
          value: "ger/seguros",
          label: "SEGURO OFERTA",
          children: [
            {
              value: "ger/seguros/SulAmerica",
              label: "SulAmerica",
              children: [
                {
                  value: "ger/seguros/SulAmerica/acessar",
                  label: "ACESSAR",
                  children: [],
                },
                {
                  value: "ger/seguros/SulAmerica/cancelar",
                  label: "CANCELAR",
                  children: [],
                },
                {
                  value: "ger/seguros/SulAmerica/incluir",
                  label: "INCLUIR",
                  children: [],
                },
                {
                  value: "ger/seguros/SulAmerica/visualizar",
                  label: "VISUALIZAR",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          value: "ger/repasse",
          label: "REPASSE",
          children: [
            {
              value: "ger/repasse/acessarNeo",
              label: "NEO CONSIG",
              children: [],
            },
            {
              value: "ger/repasse/acessarZetra",
              label: "ZETRA",
              children: [],
            },
            {
              value: "ger/repasse/acessarParquetec",
              label: "PARQUE TEC",
              children: [],
            },
          ],
        },
        {
          value: "ger/relatorio",
          label: "RELATÓRIOS",
          children: [
            {
              value: "ger/relatorio/aplicativo",
              label: "APLICATIVO",
              children: [
                {
                  value: "ger/relatorio/aplicativo/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
            {
              value: "ger/relatorio/cartao",
              label: "CARTÃO BENEFÍCIOS",
              children: [
                {
                  value: "ger/relatorio/cartaolancamentos",
                  label: "LANÇAMENTOS",
                  children: [
                    {
                      value: "ger/relatorio/cartaolancamentos/acessar",
                      label: "ACESSAR",
                      children: [],
                    },
                  ],
                },
                {
                  value: "ger/relatorio/cartaosolicitadoscancelados",
                  label: "SOLICITADOS e CANCELADOS",
                  children: [
                    {
                      value: "ger/relatorio/cartaosolicitadoscancelados/acessar",
                      label: "ACESSAR",
                      children: [],
                    },
                  ],
                },

                {
                  value: "ger/relatorio/liberarmargem",
                  label: "LIBERAR MARGEM",
                  children: [
                    {
                      value: "ger/relatorio/liberarmargem/acessar",
                      label: "ACESSAR",
                      children: [],
                    },
                    {
                      value: "ger/relatorio/liberarmargem/alterar",
                      label: "ALTERAR",
                      children: [],
                    },
                    {
                      value: "ger/relatorio/liberarmargem/visualizar",
                      label: "VISUALIZAR",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              value: "ger/relatorio/compra",
              label: "SEGUROS",
              children: [
                {
                  value: "ger/relatorio/compra/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
            {
              value: "ger/relatorio/assinaturas",
              label: "ASSINATURAS",
              children: [
                {
                  value: "ger/relatorio/assinaturas/acessar",
                  label: "ACESSAR",
                  children: [],
                },

              ],
            },
            {
              value: "ger/relatorio/emprestimo",
              label: "EMPRÉSTIMOS",
              children: [
                {
                  value: "ger/relatorio/emprestimo/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },

            {
              value: "ger/relatorio/comprasFacajus",
              label: "FACAJUS",
              children: [
                {
                  value: "ger/relatorio/comprasFacajus/acessar",
                  label: "ACESSAR",
                  children: [],
                },
                {
                  value: "ger/relatorio/comprasFacajus/alterar",
                  label: "ALTERAR",
                  children: [],
                },
                {
                  value: "ger/relatorio/comprasFacajus/visualizar",
                  label: "VISUALIZAR",
                  children: [],
                },
              ],
              
            },
          ],
        },
      ],
    },
    {
      value: "ntf",
      label: "SISTEMA PUSH",
      children: [
        {
          value: "ntf/envio",
          label: "CAMPANHA",
          children: [
            {
              value: "ntf/envio/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "ntf/envio/push/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "ntf/envio/push/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
            {
              value: "ntf/envio/push/criar",
              label: "CRIAR PUSH",
              children: [],
            },
            {
              value: "ntf/envio/push/enviar",
              label: "ENVIAR PUSH",
              children: [],
            },
            {
              value: "ntf/envio/push/consultar/margem",
              label: "CONSULTAR MARGEM (API)",
              children: [],
            },
          ],
        },
        {
          value: "ntf/analise",
          label: "ANÁLISE",
          children: [],
        },
      ],
    },
  ];

  const lista_nodes_PRISMA = [
    {
      value: "adm",
      label: "ADMINISTRATIVO",
      children: [
        {
          value: "adm/perfil",
          label: "PERFIL",
          children: [
            {
              value: "adm/perfil/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/perfil/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/perfil/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/perfil/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/usuarios",
          label: "USUÁRIOS",
          children: [
            {
              value: "adm/usuarios/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/usuarios/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/usuarios/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/usuarios/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/servidor",
          label: "SERVIDOR",
          children: [
            {
              value: "adm/servidor/acessar",
              label: "ACESSAR",
              children: [],
            },
            // {
            //   value: "adm/servidor/alterar",
            //   label: "ALTERAR",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/averbarcartao",
            //   label: "AVERBAR CARTÃO",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/averbaremprestimo",
            //   label: "AVERBAR SAQUE",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/app",
            //   label: "BLOQUEAR/DESBLOQUEAR - APP",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/volus",
            //   label: "BLOQUEAR/DESBLOQUEAR CARTÃO Benefícios",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/cancelaraverbacao",
            //   label: "CANCELAR AVERBAÇÃO",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/incluir",
            //   label: "INCLUIR",
            //   children: [],
            // },
            {
              value: "adm/servidor/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
            // {
            //   value: "adm/servidor/quitar",
            //   label: "QUITAR PARCELAS",
            //   children: [],
            // },
            // {
            //   value: "adm/servidor/seguros",
            //   label: "SEGUROS",
            //   children: [],
            // },
          ],
        },
      ],
    },
    {
      value: "corr",
      label: "CORRESPONDENTE BANCÁRIO",
      children: [
        {
          value: "corr/servidor",
          label: "SERVIDOR",
          children: [
            {
              value: "corr/servidor/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "corr/servidor/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "corr/servidor/averbarcartao",
              label: "AVERBAR CARTÃO",
              children: [],
            },
            {
              value: "corr/servidor/averbaremprestimo",
              label: "AVERBAR SAQUE",
              children: [],
            },
            {
              value: "corr/servidor/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "corr/servidor/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
            {
              value: "corr/servidor/seguros",
              label: "SEGUROS",
              children: [],
            },
          ],
        },
        {
          value: "corr/relatorio",
          label: "RELATÓRIOS",
          children: [
            {
              value: "corr/relatorio/emprestimos",
              label: "EMPRÉSTIMOS",
              children: [
                {
                  value: "corr/relatorio/emprestimos/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          value: "corr/simular",
          label: "SIMULAR SAQUE",
          children: [
            {
              value: "corr/simular/acessar",
              label: "ACESSAR",
              children: [],
            },
          ],
        },
      ],
    },
    {
      value: "ger",
      label: "GERENCIAL",
      children: [
        {
          value: "ger/relatorio",
          label: "RELATÓRIOS",
          children: [
            {
              value: "ger/relatorio/aplicativo",
              label: "APLICATIVO",
              children: [
                {
                  value: "ger/relatorio/aplicativo/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
            {
              value: "ger/relatorio/cartao",
              label: "CARTÃO BENEFÍCIOS",
              children: [
                {
                  value: "ger/relatorio/cartaolancamentos",
                  label: "LANÇAMENTOS",
                  children: [
                    {
                      value: "ger/relatorio/cartaolancamentos/acessar",
                      label: "ACESSAR",
                      children: [],
                    },
                  ],
                },
                {
                  value: "ger/relatorio/cartaosolicitadoscancelados",
                  label: "SOLICITADOS",
                  children: [
                    {
                      value: "ger/relatorio/cartaosolicitadoscancelados/acessar",
                      label: "ACESSAR",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              value: "ger/relatorio/compra",
              label: "SEGUROS",
              children: [
                {
                  value: "ger/relatorio/compra/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
            {
              value: "ger/relatorio/emprestimo",
              label: "EMPRÉSTIMOS",
              children: [
                {
                  value: "ger/relatorio/emprestimo/acessar",
                  label: "ACESSAR",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const lista_nodes_FACASEGUROS = [
    {
      value: "adm",
      label: "ADMINISTRATIVO",
      children: [
        {
          value: "adm/perfil",
          label: "PERFIL",
          children: [
            {
              value: "adm/perfil/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/perfil/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/perfil/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/perfil/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/usuarios",
          label: "USUÁRIOS",
          children: [
            {
              value: "adm/usuarios/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/usuarios/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/usuarios/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/usuarios/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
      ],
    },
    {
      value: "corr",
      label: "CORRESPONDENTE BANCÁRIO",
      children: [
        {
          value: "corr/simular",
          label: "SIMULAR SAQUE",
          children: [
            {
              value: "corr/simular/acessar",
              label: "ACESSAR",
              children: [],
            },
          ],
        },
      ],
    },
  ];

  const lista_nodes_FACAJUS = [
    {
      value: "adm",
      label: "ADMINISTRATIVO",
      children: [
        {
          value: "adm/perfil",
          label: "PERFIL",
          children: [
            {
              value: "adm/perfil/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/perfil/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/perfil/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/perfil/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
        {
          value: "adm/usuarios",
          label: "USUÁRIOS",
          children: [
            {
              value: "adm/usuarios/acessar",
              label: "ACESSAR",
              children: [],
            },
            {
              value: "adm/usuarios/alterar",
              label: "ALTERAR",
              children: [],
            },
            {
              value: "adm/usuarios/incluir",
              label: "INCLUIR",
              children: [],
            },
            {
              value: "adm/usuarios/visualizar",
              label: "VISUALIZAR",
              children: [],
            },
          ],
        },
      ],
    },
    {
      value: "ger",
      label: "GERENCIAL",
      children: [
        {
          value: "ger/relatorio",
          label: "RELATÓRIOS",
          children: [
            {
              value: "ger/relatorio/assinaturas",
              label: "ASSINATURAS",
              children: [
                {
                  value: "ger/relatorio/assinaturas/acessar",
                  label: "ACESSAR",
                  children: [],
                },

              ],
            },
          ],
        },
      ],
    },
  ];

  lista_nodes.sort((a, b) => (a.children.value > b.children.value ? -1 : 1));
  lista_nodes_PRISMA.sort((a, b) =>
    a.children.value > b.children.value ? -1 : 1
  );

  let dadosInformados = {
    checked: [],
    expanded: lista_expanded,
    nodes: lista_nodes,
  };

  let dadosInformados_PRISMA = {
    checked: [],
    expanded: lista_expanded_PRISMA,
    nodes: lista_nodes_PRISMA,
  };

  let dadosInformados_FACASEGUROS = {
    checked: [],
    expanded: lista_expanded_FACASEGUROS,
    nodes: lista_nodes_FACASEGUROS,
  };

  let dadosInformados_FACAJUS = {
    checked: [],
    expanded: lista_expanded_FACAJUS,
    nodes: lista_nodes_FACAJUS,
  };

  const [dataAcessos, setDataAcessos] = useState("");
  const parentToChild = (num) => {
    setDataAcessos(
      JSON.stringify(num).replace("[", "").replace("]", "").replaceAll('"', "")
    );
  };

  // ------------------------------------------------------------

  useEffect(() => {
    if (tipo !== "I") {
      if (tipo === "V") {
        SetCampoHabilitado(true);
      }
      SetLoadingSpinner(true);

      const perfilService = new PerfilService();
      perfilService
        .getById(id)
        .then((data) => setModelDado(data.dados), SetLoadingSpinner(false))
        .catch((error) => {
          // history.push("/semToken");
        });
    }
  }, [id, tipo]);

  useEffect(() => {
    SetLoadingSpinner(true);
    perfilService
      .getAcesso()
      .then((data) => setTipoAcesso(data.dados), SetLoadingSpinner(false))
      .catch((error) => {
        // history.push("/semToken");
      });
  }, [id, tipo]);

  useEffect(() => {
    formik.setValues(modelDado);
    let inf = [];

    if (modelDado.per_acessos !== null) {
      if (modelDado.per_acessos.trim().length !== 0) {
        inf = modelDado.per_acessos.trim().split(",");
      }
    }

    dadosInformados.checked = inf;
    dadosInformados_PRISMA.checked = inf;
  }, [modelDado, acessosPerfil]);

  useEffect(() => {
    if (retornoDado.status !== null) {
      toast.current.show({
        severity: retornoDado.status ? "success" : "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });

      if (retornoDado.status) {
        setTimeout(() => {
          history.push(link_voltar);
        }, 3000);
      }
      SetLoadingSpinner(false);
    }
  }, [retornoDado]);

  const [tipoUsuario, SetTipoUsuario] = useState(null);
  const usuariosService = new UsuariosService();

  useEffect(() => {
    SetLoadingSpinner(true);
    usuariosService
      .getTipoUsuario()
      .then((data) => SetTipoUsuario(data.dados), SetLoadingSpinner(false));
  }, []);

  function buscaPerfil() {
    //modelDado.per_acessos = ''
    modelDado.per_ust_id = formik.values.per_ust_id;
    setAcessosPerfil(formik.values.per_ust_id);
  }

  const formik = useFormik({
    initialValues: {
      per_id: modelDado.per_id,
      per_nome: modelDado.per_nome,
      per_descricao: modelDado.per_descricao,
      per_acessos: modelDado.per_acessos,
      per_ust_id: modelDado.per_ust_id,
    },
    validationSchema: yup.object({
      per_ust_id: yup.string().required("O campo é obrigatório."),
      per_nome: yup
        .string()
        .max(50, ({ max }) => `Máximo ${max} carateres.`)
        .required("O campo é obrigatório."),
      per_descricao: yup
        .string()
        .max(100, ({ max }) => `Máximo ${max} carateres.`)
        .required("O campo é obrigatório."),
    }),
    onSubmit: (values) => {
      if (tipo === "I") {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              perfilService
                .postCadastrar(
                  values.per_descricao,
                  values.per_nome,
                  values.per_ust_id,
                  dataAcessos
                )
                .then((data) => setRetornoDado(data), SetLoadingSpinner(false))
                .catch((error) => {
                  // history.push("/semToken");
                });
            });
        });
      } else {
        SetLoadingSpinner(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY_CLIENTE, {
              action: "submit",
            })
            .then((token) => {
              perfilService
                .postAtualizar(
                  values.per_id,
                  values.per_nome,
                  values.per_descricao,
                  values.per_ust_id,
                  dataAcessos
                )
                .then((data) => setRetornoDado(data))
                .catch((error) => {
                  // history.push("/semToken");
                });
            });
        });
      }
    },
  });

  const buttonHeader = (
    <div className="grid">
      {tipo !== "V" && (
        <div className="mr-2 mb-2">
          <Button
            type="submit"
            label="Salvar"
            className="p-button-raised p-button-success "
            onClick={formik.handleSubmit}
          />
        </div>
      )}
      <div className="p-button-raised mr-2 mb-2">
        <Link to={link_voltar}>
          <Button type="submit" label="Voltar" className="p-button-raised" />
        </Link>
      </div>
    </div>
  );

  return (
    <div className="card p-fluid">
      <Toast ref={toast} />
      <form>
        {header}
        {buttonHeader}
        <div className="grid">
          <div className="col-12 md:col-2">
            <div className="field">
              <span className="p-float-label  mt-5">
                <Dropdown
                  id="per_ust_id"
                  value={formik.values.per_ust_id}
                  onChange={formik.handleChange}
                  options={tipoUsuario}
                  onHide={buscaPerfil}
                  optionLabel="ust_descricao"
                  optionValue="ust_id"
                  placeholder={formik.values.per_ust_id}
                  onBlur={formik.handleBlur}
                  disabled={campoHabilitado}
                  className={classNames({
                    "p-invalid":
                      formik.touched.per_ust_id && formik.errors.per_ust_id,
                  })}
                />
                {formik.touched.per_ust_id && formik.errors.per_ust_id ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.per_ust_id}
                  </div>
                ) : null}
                <label htmlFor="per_ust_id">Empresa</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-3">
            <div className="field">
              <span className="p-float-label mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="per_nome"
                  name="per_nome"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.per_nome}
                  className={classNames({
                    "p-invalid":
                      formik.touched.per_nome && formik.errors.per_nome,
                  })}
                />
                {formik.touched.per_nome && formik.errors.per_nome ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.per_nome}
                  </div>
                ) : null}
                <label htmlFor="per_nome">Nome</label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-7">
            <div className="field">
              <span className="p-float-label  mt-5">
                <InputText
                  disabled={campoHabilitado}
                  type="text"
                  id="per_descricao"
                  name="per_descricao"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.per_descricao}
                  className={classNames({
                    "p-invalid":
                      formik.touched.per_descricao &&
                      formik.errors.per_descricao,
                  })}
                />
                {formik.touched.per_descricao && formik.errors.per_descricao ? (
                  <div style={{ fontSize: 10, color: "red" }}>
                    {formik.errors.per_descricao}
                  </div>
                ) : null}
                <label htmlFor="per_descricao">Descrição</label>
              </span>
            </div>
          </div>

          {tipo === "I" && acessosPerfil == 1 ? (
            <Acessos dados={dadosInformados} parentToChild={parentToChild} />
          ) : null}

          {modelDado.per_descricao.trim().length !== 0 &&
            modelDado.per_ust_id == 1 ? (
            <Acessos dados={dadosInformados} parentToChild={parentToChild} />
          ) : null}

          {tipo === "I" && acessosPerfil == 2 ? (
            <Acessos
              dados={dadosInformados_PRISMA}
              parentToChild={parentToChild}
            />
          ) : null}

          {modelDado.per_descricao.trim().length !== 0 &&
            modelDado.per_ust_id == 2 ? (
            <Acessos
              dados={dadosInformados_PRISMA}
              parentToChild={parentToChild}
            />
          ) : null}

          {tipo === "I" && acessosPerfil == 3 ? (
            <Acessos
              dados={dadosInformados_FACAJUS}
              parentToChild={parentToChild}
            />
          ) : null}

          {modelDado.per_descricao.trim().length !== 0 &&
            modelDado.per_ust_id == 3 ? (
            <Acessos
              dados={dadosInformados_FACAJUS}
              parentToChild={parentToChild}
            />
          ) : null}

          {tipo === "I" && acessosPerfil == 4 ? (
            <Acessos
              dados={dadosInformados_FACASEGUROS}
              parentToChild={parentToChild}
            />
          ) : null}

          {modelDado.per_descricao.trim().length !== 0 &&
            modelDado.per_ust_id == 4 ? (
            <Acessos
              dados={dadosInformados_FACASEGUROS}
              parentToChild={parentToChild}
            />
          ) : null}
        </div>
      </form>

      <LoadingSpinner visualiza={loadingSpinner} />
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Form, comparisonFn);
