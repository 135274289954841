import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EsteiraService } from "../../service/EsteiraService";
import { useHistory, Link } from "react-router-dom";

const _Index = () => {
  const history = useHistory();

  const toast = useRef(null);
  const dt = useRef(null);

  const _header = "Administrativo | Operacional | Esteira";

  const [globalFilter, setGlobalFilter] = useState(null);
  const [loadingSpinner, SetLoadingSpinner] = useState(false);

  const esteiraService = new EsteiraService();

  const [dadosEsteira, setDadosEsteira] = useState([]);

  const handleSearchInput = (content) => {
    let string = content.replace(/[.-]/g, '');
    setGlobalFilter(string);
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{_header}</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => handleSearchInput(e.target.value)}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  const processadoraBodyTemplate = (data) => {
    return (
      <div>
        {data.ope_nome}
      </div>
    )
  }

  const cpfBodyTemplate = (data) => {
    return (
      <div>
        {data.cav_cpf}
      </div>
    )
  }

  const matriculaBodyTemplate = (data) => {
    return (
      <div>
        {data.cav_matricula}
      </div>
    )
  }

  const nomeBodyTemplate = (data) => {
    return (
      <div>
        {data.cav_nome}
      </div>
    )
  }

  const visualizarBodyTemplate = (data) => {
    return (
      <div>
        <Link to={`esteiraServidor_Index/${data.cav_id}`}>
          <Button
            tooltip={"Visualizar"}
            icon={"pi pi-eye"}
            className="p-button-rounded p-button-warning"
          />
        </Link>
      </div>
    )
  }

  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  useEffect(() => {
    if (retornoDado.status === false) {     
      toast.current.show({
        severity: "error",
        summary: retornoDado.msg,
        life: 3000,
      });
    }
  }, [retornoDado]);

  useEffect(()=>{

    SetLoadingSpinner(true);

    esteiraService
      .listarOperacoesEsteira()
      .then(
        (data) => 
          data.status === true
            ? setDadosEsteira(data.dados)
            : setRetornoDado(data),
            SetLoadingSpinner(false)
      )
      .catch((error) => {              
        console.log(error)
        history.push("/semToken");        
      });

  }, [])

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={dadosEsteira}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            globalFilter={globalFilter}
            emptyMessage="Não há dados."
            header={header}
            responsiveLayout="scroll"
          >
            <Column
              field="ope_nome"
              header="Processadora"
              sortable
              body={processadoraBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_cpf"
              header="CPF"
              sortable
              body={cpfBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_matricula"
              header="Matrícula"
              sortable
              body={matriculaBodyTemplate}
              headerStyle={{ width: "14%", minWidth: "10rem" }}
            ></Column>
            <Column
              field="cav_nome"
              header="Nome"
              sortable
              body={nomeBodyTemplate}
              headerStyle={{ width: "40%", minWidth: "16rem" }}
            ></Column>
            <Column
              header="Visualizar"
              align={"center"}
              body={visualizarBodyTemplate}
              headerStyle={{ minWidth: "14rem" }}
            ></Column>
          </DataTable>
          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(_Index, comparisonFn);
