 
import { RotatingLines } from "react-loader-spinner";

export default function LoadingSpinner({ visualiza }) {
  return (
    <div className="center-form" style={{zIndex: 9999}}>    
      <RotatingLines
        strokeColor="blue"
        strokeWidth="2"
        animationDuration="1.0"
        width="300"
        visible={visualiza}          
      />

    </div>
  );
}
