import api from "../Api";

export class PushService {

  async indicador(inicio, fim, campanha, convenio) {
    return await api
      .post("notificacao/dashboard/indicador", 
        {
          inicio: inicio,
          fim: fim,
          campanha: campanha,
          convenio: convenio,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getPushList(inicio, fim, campanha, status) {
    return await api
      .post("notificacao/push/listar", 
        {
          inicio: inicio,
          fim: fim,
          campanha: campanha,
          status: status,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getPushAnaliseList(inicio, fim, campanha, convenio) {
    return await api
      .post("notificacao/push/analise-listar", 
        {
          inicio: inicio,
          fim: fim,
          campanha: campanha,
          convenio: convenio,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getPushById(campanha) {
    return await api
      .post("notificacao/push/campanha", 
        {
          campanha: campanha,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getCampanhasList() {
    return await api
      .get("notificacao/push/campanha-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getConveniosList() {
    return await api
      .get("notificacao/push/convenio-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getProdutosList() {
    return await api
      .get("notificacao/push/produto-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getPlanosList() {
    return await api
      .get("notificacao/push/plano-listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getCategoriasList(convenios) {
    return await api
      .post("notificacao/push/categorias-listar", 
        {
          convenios: convenios,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async saveCampainNotification(
    convenios, produtos, planos, campanha, titulo, link, message, calendar, previewImage, principalImage, sendToEveryone, // Obrigatórios 
    sendToAbsent, minimumAge, maximumAge, categorias, searchMargin, minimumMargin, maximumMargin, alcance, alcanceMargem, update // Opicionais 
  ) {
    return await api
      .post("notificacao/push/cadastrar", 
        {
          convenios: convenios,
          produtos: produtos,
          planos: planos,
          nome: campanha,
          titulo: titulo,
          link: link,
          message: message,
          calendar: calendar,
          previewImage: previewImage,
          principalImage: principalImage,
          todos: sendToEveryone,
          inativo: sendToAbsent,
          minimumAge: minimumAge,
          maximumAge: maximumAge,
          categorias: categorias,
          useMargin: searchMargin,
          minimumMargin: minimumMargin,
          maximumMargin: maximumMargin,
          alcance: alcance,
          alcanceMargem: alcanceMargem,
          update: update
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async activatePush(id, status) {
    return await api
      .post("notificacao/push/ativa-push", {
        id:id,
        status:status
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async sendNotification(
    convenios, produtos, planos, campanha, titulo, link, message, calendar, previewImage, principalImage, sendToEveryone, // Obrigatórios 
    sendToAbsent, minimumAge, maximumAge, categorias, searchMargin, minimumMargin, maximumMargin, alcance, alcanceMargem, update // Opicionais 
  ) {
    return await api
      .post("notificacao/push/send", {
        convenios: convenios,
        produtos: produtos,
        planos: planos,
        nome: campanha,
        titulo: titulo,
        link: link,
        message: message,
        calendar: calendar,
        previewImage: previewImage,
        principalImage: principalImage,
        todos: sendToEveryone,
        inativo: sendToAbsent,
        minimumAge: minimumAge,
        maximumAge: maximumAge,
        categorias: categorias,
        useMargin: searchMargin,
        minimumMargin: minimumMargin,
        maximumMargin: maximumMargin,
        alcance: alcance,
        alcanceMargem: alcanceMargem,
        update: update
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getAlcance(convenios, sendToEveryone, sendToAbsent, minimumAge, maximumAge, categorias) {
    return await api
      .post("notificacao/push/alcance", {
        convenios: convenios,
        everyone: sendToEveryone,
        absent: sendToAbsent,
        minimumAge: minimumAge,
        maximumAge: maximumAge,
        categorias: categorias,
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getAlcanceMargem(convenios, sendToEveryone, sendToAbsent, minimumAge, maximumAge, categorias, produtos, minimumMargin, maximumMargin) {
    return await api
      .post("notificacao/push/alcance-margem", {
        convenios: convenios,
        everyone: sendToEveryone,
        absent: sendToAbsent,
        minimumAge: minimumAge,
        maximumAge: maximumAge,
        categorias: categorias,
        produtos: produtos,
        minimumMargin: minimumMargin,
        maximumMargin: maximumMargin,
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
}