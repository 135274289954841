import React from "react";

const AppChoosePlatform = ({SelectPlatform}) => {

  const buttonStyle = {
    paddingTop: 120,
    paddingBottom: 120,
    paddingRight: 50,
    paddingLeft: 50,
    fontSize: 24,
    fontWeight: 'bold',
    color: '#000',
    borderRadius: 20,
    boxShadow: "4px 4px 5px 0px grey",
    borderWidth: 0,
    cursor: 'pointer',
    width: 450
  }

  return (
    <>
      <div className="grid">
        <div className="center-form">
          <div className="card p-fluid p-8">

            <div className="flex flex-column" style={{paddingLeft: 120, paddingRight: 120, paddingTop: 120, gap: 120}}>

              <div className="flex flex-row justify-content-around" style={{gap: 250}}>

                <button style={buttonStyle} onClick={()=>SelectPlatform("adm")}>
                  Painel Administrativo
                </button>

                <button style={buttonStyle} onClick={()=>SelectPlatform("ntf")}>
                  Painel de Notificações
                </button>

              </div>

              <div className="flex justify-content-center">
                <img
                  src="assets/layout/images/logo.png"
                  alt="Logo"
                  height="80"
                  className="mt-8 px-8"
                />
              </div>

            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default AppChoosePlatform;
