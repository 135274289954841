import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Funcoes } from "../../../utils/funcoes";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import LoadingSpinner from "../../../components/LoadingSpinner";
import Exportar from "../../../components/Exportar";
import { PushService } from "../../../service/Notificacao/PushService";

const Analise = () => {

  const history = useHistory();

  const toast = useRef(null);
  const dt = useRef(null);

  const utils = new Funcoes();
  const pushService = new PushService();

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [campanha, setCampanha] = useState(0);
  const [campanhaSelectList, setCampanhaSelectList] = useState([]);

  const [convenio, setConvenio] = useState("");
  const [convenioSelectList, setConvenioSelectList] = useState([]);

  const [analises, setAnalises] = useState([]);
  
  const [retornoDado, setRetornoDado] = useState({
    status: null,
    msg: "",
  });

  useEffect(() => {
    if (retornoDado.status === false) {
       
      toast.current.show({
        severity: "error",
        summary: retornoDado.mensagem,
        life: 3000,
      });
    }
  }, [retornoDado]);

  const exportBtn = () => {
    const cols = [
      { field: "campanha", header: "Campanha" },
      { field: "titulo", header: "Título" },
      { field: "mensagem", header: "Mensagem" },
      { field: "link", header: "Link" },
      { field: "data_cadastro", header: "Data Cadastro" },
      { field: "dia_agendado", header: "Dia Agendado" },
      { field: "hora_agendada", header: "Horário Agendado" },
      { field: "status_envio", header: "Status Envio" },
      { field: "cliques", header: "Cliques" },
      { field: "enviados", header: "Enviados" },
    ];

    return (
      <React.Fragment>
        <Exportar dt={dt} cols={cols} dadosService={analises} id="header-export-btn"/>
      </React.Fragment>
    );
  };
  
  const formFields = (
    <>
      <div className="grid gap-5">
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Data Início</span>
          <InputMask
            mask={"De: 99/99/9999"}
            type="text"
            id="form-initial-date"
            className="text-xl"
            onChange={(data)=>setDataInicial(data.value)}
            placeholder="De: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Data Final</span>
          <InputMask
            mask={"Até: 99/99/9999"}
            type="text"
            id="form-final-date"
            className="text-xl"
            onChange={(data)=>setDataFinal(data.value)}
            placeholder="Até: __/__/____"
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Campanha</span>
          <Dropdown
            id="form-campanha"
            value={campanha} 
            options={campanhaSelectList}
            className="text-xl"
            onChange={(data)=>setCampanha(data.value)}
            placeholder="Selecione..."
          />
        </div>
        <div className="col-fixed flex flex-column" style={{width: 200}}>
          <span>Convênio</span>
          <Dropdown
            id="form-status-envio"
            value={convenio}
            options={convenioSelectList}
            className="text-xl"
            onChange={(data)=>setConvenio(data.value)}
            placeholder="Selecione..."
          />
        </div>
      </div>
    </>
  );
  
  function submitSearchForm() {

    const [initialDate, finalDate] = [utils.handleDate(dataInicial), utils.handleDate(dataFinal)];

    if (!utils.isValidDate(initialDate) && !initialDate) {
      setRetornoDado({
        status: false,
        msg: "Data início inválida.",
      }) 
      return false
    }

    if (!utils.isValidDate(finalDate) && !finalDate) {
      setRetornoDado({
        status: false,
        msg: "Data final inválida.",
      }) 
      return false
    }

    setLoadingSpinner(true);
    pushService
      .getPushAnaliseList(initialDate, finalDate, campanha, convenio).then(
        (data) =>
          data.status === true ? setAnalises(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error);
     });

  }

  function clearFormFields() {
    document.getElementById("form-initial-date").value = "";
    document.getElementById("form-final-date").value = "";
    setCampanha(0);
    setConvenio("");
  }

  const formSubmit = (
    <>
      <div className="flex flex-row gap-2">
        <Button label="Buscar" onClick={submitSearchForm} id="form-search-btn"></Button>
        <Button label="Limpar" className="p-button-outlined" onClick={clearFormFields} id="form-clear-btn"></Button>
      </div>
    </>
  )

  useEffect(()=>{

    setLoadingSpinner(true);
    pushService
      .getCampanhasList().then(
        (data) =>
          data.status === true ? setCampanhaSelectList(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
     });
  
    setLoadingSpinner(true);
    pushService
      .getConveniosList().then(
        (data) =>
          data.status === true ? setConvenioSelectList(data.dados) : setRetornoDado(data),
        setLoadingSpinner(false)
      )
      .catch((error) => {
        history.push("/semToken");
        console.error(error)
    });
  }, [])

  const convenioTemplate = (data) => {
    return <>
      <p>{data.convenio}</p>
    </>
  } 

  const campainTemplate = (data) => {
    return <>
      <p>{data.titulo}</p>
    </>
  }

  const totalTemplate = (data) => {
    return <>
      <p>{data.total}</p>
    </>
  } 

  const enviadosTemplate = (data) => {
    return <>
      <p>{data.enviados}</p>
    </>
  } 

  const recebidosTemplate = (data) => {
    return <>
      <p>{data.recebidos}</p>
    </>
  } 

  const clicksTemplate = (data) => {

    return <>
      <p>{data.cliques}</p>
    </>
  }

  const visualizadoTemplate = (data) => {

    return <>
      <p>{data.visualizado}</p>
    </>
  }

  const dayTemplate = (data) => {
    return <>
      <p>{data.dia}</p>
    </>
  } 

  const dateTemplate = (data) => {
    return <>
      <p>{utils.formataDataParaDDMMYYYY(data.dia_agendado)}</p>
    </>
  } 

  const hourTemplate = (data) => {
    return <>
      <p>{utils.formataHoraParaHHII(data.hora_agendada)}</p>
    </>
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          
          <Toolbar
            className="mb-4"
            left={
              <Link to={utils.permissaoAcesso("ntf/envio/push/criar") && "/notificacao/select"}>
                  <Button 
                    icon={utils.permissaoAcesso("ntf/envio/push/criar") ? "pi pi-plus" : "pi pi-lock"}
                    label="Criar Notificação" 
                    className="p-button-success"
                    id="header-create-notification-btn"
                  />
              </Link>
            }
            right={exportBtn}
          ></Toolbar>
          
          <Toolbar
            className="mb-4"
            left={formFields}
            right={formSubmit}
          ></Toolbar>
        
          <DataTable
            ref={dt}
            value={analises}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Apresenta {first} a {last} de {totalRecords} registros"
            emptyMessage="Não foram encontrados envios."
            responsiveLayout="scroll"
            loadingIcon="pi pi-spin pi-spinner"
          >
            <Column
              field="convenio"
              header="Convênio"
              body={convenioTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="campanha"
              header="Campanha"
              sortable
              body={campainTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="total_usuarios"
              header="Total de usuários"
              sortable
              body={totalTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="dispositivos_enviados"
              header="Dispositivos enviados"
              sortable
              body={enviadosTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="recebidos"
              header="Recebidos"
              body={recebidosTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="cliques"
              header="Cliques"
              sortable
              body={clicksTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="visualizacoes"
              header="Visualizações"
              sortable
              body={visualizadoTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="dia"
              header="Dia"
              sortable
              body={dayTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="data"
              header="Data"
              sortable
              body={dateTemplate}
              headerStyle={{ width: "12%" }}
            />
            <Column
              field="hora"
              header="Hora"
              align={'center'}
              body={hourTemplate}
              headerStyle={{ width: "14%" }}
            />
          </DataTable>

          <LoadingSpinner visualiza={loadingSpinner} />
        </div>
      </div>
    </div>
  )
}

export default Analise;